import React from "react";

const IsmsPolicy = () => {
  return (
    <div className="bg-richblack-900  text-white">
      <div className="w-[85%] m-auto flex flex-col gap-9 py-20">
        <h1 className="md:text-6xl text-2xl font-poppinsBold md:h-32 items-center flex ">
          ISMS Policy
        </h1>

        <div className="border border-[#4e4e4e] rounded-lg  p-[2rem]">
          <h2 className="text-xl text-[#717171] font-poppinsRegular  py-[2rem]">
            Dazala Tech Private Limited. Information Security Management is
            established to ensure the confidentiality, integrity and
            availability of all information systems, assets and processing
            facilities involved in the Design, Development and Customer support
            for Digital Mobile Applications with a commitment to
          </h2>

          <ul className="text-xl text-[#717171]  font-poppinsRegular list-disc">
            <li>
              Identify, evaluate, and manage risks that can affect our
              customers, our operations and our critical infrastructures in
              alignment with our risk management process and implement the
              applicable information security controls.
              <br /> <br />
            </li>
            <li>
              Provide information security awareness training and establish a
              company-wide information security culture through learning and
              development programs.
              <br /> <br />
            </li>
            <li>
              Develop and implement objectives, policies, procedures, and
              processes to support the operations of the ISMS and clearly define
              roles and responsibilities.
              <br /> <br />
            </li>
            <li>
              Partner with our ecosystem across customers, partners, and
              suppliers in the value chain to build trust and ensure our
              employees, vendors or third-party contractors adhere to our
              information security policies, procedures, standards, and
              guidelines.
              <br /> <br />
            </li>
            <li>
              Comply with applicable laws, policies, standards and regulations
              on information security management systems.
              <br /> <br />
            </li>
            <li>
              Continual improvement of the objectives and processes related to
              information security management systems.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default IsmsPolicy;
