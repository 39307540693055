import React from "react";
import { useNavigate } from "react-router";
import kuberPeLogo from "../assets/images/darkBgLogo.png";

const Navbar = ({ backgroundColour }) => {
  const navigate = useNavigate();

  return (
    <nav
      onClick={() => navigate("/")}
      className={`${backgroundColour} text-white w-[100%] h-[10vh] flex items-center justify-center`}
    >
      <div className="md:w-[85%] w-[95%] md:h-[70%] h-[100%] subpixel-antialiased">
        <div className="h-[100%] md:w-[18%] w-[100%] cursor-pointer">
          <img
            src={kuberPeLogo}
            alt="KuberPe"
            className="h-[100%] w-[100%]"
            style={{ objectFit: "contain" }}
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
