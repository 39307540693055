import React from "react";

const Terms = () => {
  return (
    <div className="bg-richblack-900 text-white">
      <div className="w-[85%] m-auto flex gap-9 flex-col py-20 font-poppinsRegular">
        <h1 className="md:text-6xl text-2xl font-poppinsBold md:h-32 items-center flex ">
          Terms of Use | KuberPe
        </h1>
        <h2 className="leading-9 text-xl text-[#717171] font-poppinsRegular">Welcome to KuberPe!</h2>
        <h2 className="text-xl text-[#717171] font-poppinsRegular">
          We reward you with in-game coins when you save and invest. These coins
          will help you to play KuberPe Games where you can win Rewards. Read
          the Game Rules carefully and make sure your state allows such
          participation.
        </h2>

        {/* question and answer */}
        <div className="text-xl leading-8 my-10 ">
          <h2 className="font-poppinsMedium">What is this document?</h2>
          <h2 className="text-[#717171] font-poppinsRegular">
            This document captures the terms ("Terms") on which we are offering
            the KuberPe Platform to you. It covers the nature of our service,
            when and how you can use it, and other rights and obligations that
            govern our relationship with you.
            <br /> <br />
          </h2>

          <h2 className="font-poppinsMedium">Who do these Terms apply to?</h2>
          <h2 className="text-[#717171] font-poppinsRegular">
            It applies to anyone who visits the KuberPe Platform, or create an
            account on the KuberPe Platform (" users", "you" or "your").
            <br /> <br />
          </h2>

          <h2 className="font-poppinsMedium">Why should you pay attention?</h2>
          <h2 className="text-[#717171] font-poppinsRegular">
            By accessing or using the KuberPe Platform, you are bound by these
            Terms. You acknowledge our rights and agree to adhere to your
            obligations set-out here.
            <br /> <br />
          </h2>

          <h2 className="font-poppinsMedium">Should you read the entire document?</h2>
          <h2 className="text-[#717171] font-poppinsRegular">
            Absolutely. These Terms constitute a legally binding agreement, so
            please read them carefully before using the KuberPe Platform. For
            your ease, we have provided summaries of some crucial Terms. But, in
            case of any conflict between the summaries and these Terms, the
            Terms will prevail.
            <br /> <br />
          </h2>

          <h2 className="font-poppinsMedium">Can these Terms change?</h2>
          <h2 className="text-[#717171] font-poppinsRegular">
            Yes. We may revise these Terms as well as update the KuberPe
            Platform from time to time, so please keep visiting this page
            regularly. If you do not agree with any part of these Terms, please
            stop using the KuberPe Platform immediately.
            <br /> <br />
          </h2>

          <h2 className="font-poppinsMedium">Can you raise a concern?</h2>
          <h2 className="text-[#717171] font-poppinsRegular">
            Definitely. If you have any queries or concerns regarding these
            Terms or the KuberPe Platform, please contact our Grievance Officer
            (see Clause 11 for details).
            <br /> <br />
          </h2>
        </div>

        {/* OUR SERVICES */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
            1. OUR SERVICES <br /> <br />
          </h2>
          
          <ol className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[1.5rem] font-poppinsRegular list-decimal">
          <h2 className="text-xl">
            We make savings and investments easy, fun, and rewarding! If you are
            making an account with us, make sure you are above 18 and legally
            competent to do so.
          </h2> <br /> 
            <li>
              Nature of our service: We allow you to save and invest in
              third-party financial products listed on the KuberPe platform, and
              give you such features that help you keep track of your savings
              and investments. For every saving or investment, you make, we
              incentivise users by giving them free entry to games hosted on the
              KuberPe Platform. Our services are elaborately covered in Clause 2
              (Savings and Investments) and Clause 3 (Games and Contests) of
              these Terms.
              <br /> <br />
            </li>
            <li>
              Eligibility: You may use our services only if you are legally
              permitted to do so under applicable laws. If you are under 18,
              unsound, intoxicated or otherwise "incompetent to contract" under
              the Indian Contract Act 1872, you are not eligible to use the
              KuberPe Platform.
              <br /> <br />
            </li>
            <li>
             Creating a KuberPe account: To use the KuberPe Platform, you
              must create an account with us ("KuberPe Account"). We will ask
              you for your mobile number, which we will authenticate using a
              one-time password. We may also need your name, email-id, age,
              residential address, and such other information or documents we
              think is necessary to create a KuberPe Account for you. If you
              want to subscribe to any financial product, we will ask you
              information and documents as per Clause 2.3 (Savings and
              Investments) of these Terms.
              <br /> <br />
            </li>
            <li>
              Disclaimer: The KuberPe Platform and our services are being
              provided to you on an "as is" and "as available" basis without any
              warranty or condition. Our service and your access to the KuberPe
              Platform may not always be uninterrupted or error-free. In case
              you face any direct, indirect, or consequential loss or damage
              from your use or inability to use the KuberPe Platform, we are not
              liable.
              <br /> <br />
            </li>
          </ol>
        </div>

        {/* SAVINGS AND INVESTMENTS */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
            2. SAVINGS AND INVESTMENTS <br /> <br />
          </h2>
          
          <ol className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[2rem] font-poppinsRegular list-decimal">
          <h2 className="text-xl">
            We only list and enable you to invest in financial products. We do
            not offer them on our own. Read the terms of our partner Augmont and
            Gold Supplier thoroughly before investing. We are not liable for
            their actions or products.
          </h2><br />
            <li>
            Listing of financial products
              <br /> <br />
              <ul className="list-disc">
                <li>
                  We are registered as a mutual funds distributor under the
                  Association of Mutual Funds in India (AMFI), a government
                  organisation under the purview of the Securities and Exchange
                  Board of India ("SEBI "), and are legally permitted to list
                  financial products.
                </li>
                <li>
                  We partner with asset management companies ("AMCs") to show
                  financial products offered by them on the KuberPe Platform.
                  These products are selected on the basis of your risk profile,
                  their historical performance, and their potential to give you
                  more returns than a traditional savings bank account.
                </li>
                <li>
                  We also partner with gold suppliers ("Gold Supplier") to list
                  their gold accumulation plans on the KuberPe Platform.
                </li>
              </ul>
              <br /> <br />
            </li>
            <li>
              Facilitating savings and investments: We enable you to subscribe
              and redeem mutual fund units and gold accumulation plans from our
              partner AMCs and Gold Suppliers. Based on your preference, you can
              select any financial product on the KuberPe Platform and we will
              pass on your funds (and related documents/instructions) to the
              respective AMCs or Gold Supplier.
              <br /> <br />
            </li>
            <li>
              KYC verification <br /> <br />
              <ul className="list-disc">
                <li>
                  Before you start investing through the KuberPe Platform, we
                  will do a know your customer ("KYC") verification. This
                  verification will be conducted as per applicable law and the
                  terms and conditions of our partner AMCs/Gold Supplier.
                </li>
                <li>
                  We will assist our partner AMCs/Gold Supplier in the KYC
                  verification process and may help third-parties service
                  providers in the KYC process. We (or the service provider) may
                  ask you for either of these documents for KYC verification:
                  (a) PAN card; (b) Aadhar card; (c) occupation; (d) salary
                  bracket; (e) bank account details, or any other information or
                  document needed for KYC verification.
                </li>
                <li>
                  Once your KYC verification is complete, you will be eligible
                  to save and invest in financial products listed on the KuberPe
                  Platform.
                </li>
              </ul>
              <br /> <br />
            </li>
            <li>
              Deposit of funds <br />
              <br />
              <ul className="list-disc">
                <li>
                  To save or invest in any financial product, you can use the
                  different payment modes (like UPI and IMPS) available on the
                  KuberPe Platform.
                </li>
                <li>
                  Any amount you transfer using the KuberPe Platform will go to
                  the respective bank account of our partner AMCs or Gold
                  Supplier, either directly or through payment intermediaries.
                  At no point of time, do user funds come to the Company’s bank
                  account.
                </li>
              </ul>
              <br /> <br />
            </li>
            <li>
              Withdrawal of funds
              <br />
              <br />
              <ul className="list-disc">
                <li>
                  Subject to KYC verification and the terms of our partner AMCs
                  or Gold Supplier (as applicable), you can redeem your
                  investment at any time using the KuberPe Platform.
                </li>
                <li>
                  As soon as you make a withdrawal request on the KuberPe
                  Platform, we pass on that instruction to our partner AMCs or
                  Gold Supplier who will then deposit the amount in your
                  registered bank account within 2 (two) business days.
                </li>
              </ul>
              <br /> <br />
            </li>
            <li>
              Acceptance to partner terms: The financial product you save or
              invest in using the KuberPe Platform is provided by our partner
              AMCs or Gold Supplier, and not us. Each AMCs or Gold Supplier you
              deal with will have its own protocols, rules, terms of use, and
              privacy practices ("Partner Terms") to provide you the financial
              product. You are responsible to understand and follow the Partner
              Terms yourself. We do not accept any liabilities that may arise
              from your relationship with the relevant AMCs or Gold Supplier, as
              the case may be. <br />
              <br />
            </li>
            <li>
              Disclaimers <br />
              <br />
              <ul className="list-disc">
                <li>
                  By listing any financial product on the KuberPe Platform, we
                  do not make any representation, warranty, or guarantee
                  regarding the quality, nature, or bona-fides of the mutual
                  fund (offered by the AMCs) or gold accumulation plans (offered
                  by the Gold Supplier), including the security and rate of
                  returns promised by them.
                </li>
                <li>
                  You agree that our services do not constitute an investment
                  advice and you are solely responsible for your investment
                  decisions. In case you suffer any loss or damage by investing
                  in a mutual fund or gold accumulation plan listed on the
                  KuberPe Platform, we disclaim all liabilities.
                </li>
                <li>
                  The information in relation to the mutual funds or gold
                  accumulation plans (including their current and live rates)
                  are received from our partner AMCs and Gold Supplier directly.
                  The KuberPe Platform displays this information on an "as is"
                  basis and is not, in any manner, responsible for the accuracy
                  or completeness of such information either at the time of
                  purchase or redemption.
                </li>
                <li>
                  The funds you transfer or request to withdraw using the
                  KuberPe Platform are processed by your bank, our partner
                  AMCs/Gold Supplier, and their respective banks and payment
                  intermediaries. We are not responsible in case there is a
                  delay in the transfer of funds or in delivering the financial
                  product you subscribed for.
                </li>
                <li>
                  We share your information and documents to our partner AMCs
                  and Gold Suppliers through secure channels. However, in case
                  of unauthorized access or any security breach, we assume no
                  liability or responsibility for the disclosure of your
                  information or documents to third-parties.
                </li>
              </ul>
              <br /> <br />
            </li>
          </ol>
        </div>

        {/* 3.GAMES AND CONTESTS */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
            3. GAMES AND CONTESTS <br /> <br />
          </h2>
          
          <ol className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[2rem] font-poppinsRegular list-decimal">
          <h2 className="text-xl">
            We reward you with in-game coins when you save and invest. These
            coins will help you to play KuberPe Games where you can win Rewards.
            Read the Game Rules carefully and make sure your state allows such
            participation.
          </h2><br />
            <li>
              Nature of games and contests: We offer promotional games and
              contests on the KuberPe Platform to incentivise users and motivate
              them to save and invest ("KuberPe Games"). All KuberPe Games are
              provided by us on an "as is" basis with no guarantee or warranty
              of continuity. We may choose to modify, replace, abandon, or add
              any KuberPe Game on the platform at our discretion.
              <br /> <br />
            </li>
            <li>
              Entry to KuberPe Games: For every saving and investment made
              through the KuberPe Platform, we will offer you a free entry to
              KuberPe Games hosted on the KuberPe Platform. We also give you a
              free entry to the KuberPe Games when you refer someone to the
              KuberPe Platform in accordance with Clause 7 (Referrals). We do
              not allow users to pay any money or similar consideration to
              participate in the KuberPe Games.
              <br /> <br />
            </li>
            <li>
              Contest rules: Each KuberPe Game is governed by its own rules,
              instructions, and reward conditions ("Game Rules "). The Game
              Rules for each KuberPe Game will appear as a link or be displayed
              on the KuberPe Platform. Read these Game Rules before
              participating in KuberPe Games. We retain the right to modify
              these Game Rules at any time.
              <br /> <br />
            </li>
            <li>
              Rewards: In line with the Game Rules, if you win at any KuberPe
              Game, we will either reward you with cash rewards, discounts,
              offers, deals, or third-party goods and services ("Rewards "). The
              nature and timeline to disburse the Rewards is at the sole
              discretion of the Company. You are only eligible to receive the
              Reward if your KuberPe account is KYC verified.
              <br /> <br />
            </li>
            <li>
              Geographical restrictions: We may notify, at any point, that
              users from a particular state or jurisdiction are not eligible to
              access or participate in some or all the KuberPe Games.
              <br /> <br />
            </li>
            <li>
              Disclaimer <br />
              <br />
              <ul className="list-disc">
                <li>
                  None of the KuberPe Games are offered to users for money, and
                  hence they do not qualify as "gambling" under Indian law. In
                  case any KuberPe Game is restricted or prohibited in your
                  state, you are responsible to notify us and avoid
                  participation in such KuberPe Game.
                </li>
                <li>
                  The disbursal of Rewards is at our complete discretion. You
                  do not have any claim or right over any Reward declared by us
                  until it is deposited in your account.
                </li>
                <li>
                  We will make deductions under applicable law before
                  disbursing any Reward to you. We are not responsible for any
                  tax implications on you from your participation in the KuberPe
                  Games or any Rewards we disburse to you. You must obtain an
                  independent tax advice before entering into a KuberPe Game or
                  receiving any Reward from us.
                </li>
              </ul>
              <br /> <br />
            </li>
          </ol>
        </div>

        {/* 4.YOUR OBLIGATIONS */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
            4. YOUR OBLIGATIONS <br /> <br />
          </h2>
          
          <ol className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[2rem] font-poppinsRegular list-decimal">
          <h2 className="text-xl">
            All information you provide us or our partners should be 100% true.
            Keep your account details safe and make sure you are using the
            latest version. Do not misuse the platform or infringe any of our
            intellectual property.
          </h2><br />
            <li>
              Give bona-fide credentials: The information and documents you
              provide help us to understand your risk profile and curate our
              services for you. It is hence crucial that all information you
              provide to us is true, complete, not misleading and is regularly
              updated by you. If any part of your information is incorrect,
              incomplete or misleading, it would be a breach of these Terms and
              a violation of the law. Please write to our Grievance Officer if
              you wish to notify us of any incorrect, incomplete or misleading
              information you have provided us.
              <br /> <br />
            </li>
            <li>
              Be responsible: Please ensure that you keep your mobile device
              and login credentials safe and secure. You are solely responsible
              for all activities that occur from your KuberPe account and all
              debits or credits to your bank account. If you think someone has
              gained access to your KuberPe account, please contact our
              Grievance Officer immediately.
              <br /> <br />
            </li>
            <li>
              No unlawful use: You agree that you will not use the KuberPe
              Platform or our services for any purpose that is unlawful in any
              jurisdiction or not permitted by these Terms.
              <br /> <br />
            </li>
            <li>
              Respect our intellectual property: You agree to not interfere
              with or access non-public areas of the KuberPe Platform. You will
              not introduce any trojans, viruses, any other malicious software
              on the KuberPe Platform. Additionally, you will not probe, scan,
              or test the vulnerability of any system, security or
              authentication measures implemented by us. If you tamper or
              attempt to tamper with our technological design and architecture,
              we may terminate your KuberPe account and take relevant legal
              action against you.
              <br /> <br />
            </li>
            <li>
              Keep the KuberPe Platform updated: You must ensure that you keep
              updating the KuberPe Platform as and when we release new versions
              of it. Any failure to do so may make you incapable of using the
              KuberPe Platform or our services.
              <br /> <br />
            </li>
          </ol>
        </div>

        {/* 5.PRIVACY AND DATA PROTECTION */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
          5. PRIVACY AND DATA PROTECTION <br /> <br />
          </h2>
          <h2 className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-5 font-poppinsRegular">
          Our privacy policy (available at <span className="text-[#2E8BFF]">Privacy Policy</span>) explains how we treat your personal data and protect your privacy when you use our services. By accessing or using the KuberPe Platform, you agree that we can use such data in accordance with our privacy policy.
          </h2>
        </div>

        {/* 6.INTELLECTUAL PROPERTY */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
          6. INTELLECTUAL PROPERTY <br /> <br />
          </h2>
         
          <ol className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[2rem] font-poppinsRegular list-decimal">
          <h2 className="text-xl">
          All the content and services on the KuberPe Platform is our intellectual property. You cannot duplicate or commercially exploit it in any manner.
          </h2><br />
            <li>
            Ownership: The KuberPe Platform and our services are owned and operated by the Company. The visual interfaces, graphics, design, compilation, information, computer code (including source code and object code), products, software, services, and all other elements of our services and the KuberPe Platform is our intellectual property and is protected under applicable laws.
              <br /> <br />
            </li>
            <li>
            Limited license: We give you a limited, non-transferrable, non-sublicensable and revocable license to access the KuberPe Platform, and avail our services for your own personal and lawful use only.
              <br /> <br />
            </li>
            <li>
            Unauthorised social-groups: We are present on <span className="text-[#2E8BFF]">LinkedIn , Facebook , Twitter </span>, and <span className="text-[#2E8BFF]">Instagram</span> on these hyperlinked accounts. Apart from these, we do not have any community or social-groups on any social media platform or website. No one is allowed to create any such group or account using our name or a reference to the KuberPe Platform. In case any liability arises from the use of such group or account, we disclaim all responsibility.
              <br /> <br />
            </li>
            <li>
            No misappropriation: You agree not to remove, obscure, or alter the KuberPe Platform or any third party’s intellectual-property affixed or contained on the KuberPe Platform. You are not entitled to duplicate, distribute, create derivative works of, display, or commercially exploit the KuberPe Platform, our services, features or facilities without our prior written permission.
              <br /> <br />
            </li>
          </ol>
        </div>

        {/* 7.THIRD-PARTY CONTENT AND ADVERTISEMENT */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
          7. THIRD-PARTY CONTENT AND ADVERTISEMENT <br /> <br />
          </h2>
          <ol className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[2rem] font-poppinsRegular list-decimal">
            <li>
            We may, from time to time, display offers, advertisements, or links from third parties on the KuberPe Platform. However, this does not mean we endorse these third parties or warrant and guarantee the quality of goods or services they offer.
              <br /> <br />
            </li>
            <li>
            If you avail any goods or services of such third parties, such arrangement will solely be between you and the third party. You should avail such goods or services only after understanding the relevant third-party’s terms of use, privacy policies, or any other instructions of purchase.
              <br /> <br />
            </li>
          </ol>
        </div>

        {/* 8.REFERRALS */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
          8. REFERRALS <br /> <br />
          </h2>
          
          <ol className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[2rem] font-poppinsRegular list-decimal">
          <h2 className="text-xl">
          Refer our platform to others and get Rewards! Make sure you and your referee follow all necessary steps, or both your Rewards will be lost.
          </h2><br />
            <li>
            Our referral program: We encourage you to refer the KuberPe Platform to your friends, peers, relatives, or anyone who could benefit from our service ("Referee"). Each user has a unique referral link which is available on your KuberPe Platform. You can use this referral link to invite users through messages, social media, or third-party platforms.
              <br /> <br />
            </li>
            <li>
            Referral reward: Both you and the Referee are given Rewards upon making a valid and successful referral. The Rewards are given at our discretion and into your respective accounts on the KuberPe Platform.
              <br /> <br />
            </li>
            <li>
            Conditions to receive referral reward: Each referral needs to meet certain pre-conditions before you or the Referee is eligible for the Reward. These conditions are available on the KuberPe Platform which you should refer to every time you make a referral.
              <br /> <br />
            </li>
          </ol>
        </div>

        {/* 9.TERMINATION */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
          9. TERMINATION <br /> <br />
          </h2>
          
          <ol className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[2rem] font-poppinsRegular list-decimal">
          <h2 className="text-xl">
          You acknowledge and agrees that the Company may, at its sole discretion, without notice, suspend or terminate its services and your access to the KuberPe Platform, if:
          </h2><br />
            <li>
            You engage in any conduct or activities that the Company, in its sole discretion, believes to be violative of these Terms, or is otherwise inappropriate for your continued access to the KuberPe Platform.
              <br /> <br />
            </li>
            <li>
            We learn that the user is dead, bankrupt, or lacks legal capacity to avail our service
              <br /> <br />
            </li>
            <li>
            We are required to do so by applicable law.
              <br /> <br />
            </li>
            <li>
            Our partner AMCs or Gold Supplier, with whom you availed the financial product, has terminated its relationship with you or has recommended us to block you.
              <br /> <br />
            </li>
            <li>
            The provision of our services to You is no longer commercially viable or feasible for us.
              <br /> <br />
            </li>
          </ol>
        </div>

        {/* 10.INDEMNITY */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
          10. INDEMNITY<br /> <br />
          </h2>
          <h2 className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-5 font-poppinsRegular">
          You agree to defend, indemnify and hold the Company, its group entities, vendors, business partners, associates, directors, officers, and employees harmless from any and all claims, loss, liabilities, damages, costs, action, expenses or any liability that they may suffer from your violation of these Terms or your unauthorized use of the KuberPe Platform.
          </h2>
        </div>

        {/* 11.GRIEVANCE REDRESSAL */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
          11. GRIEVANCE REDRESSAL <br /> <br />
          </h2>
          <ol className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[2rem] font-poppinsRegular list-decimal">
            <li>
            Any complaints or concerns with regards to the content on KuberPe Platform, our services, or your disagreement to these Terms, shall be immediately informed, with all the relevant details, to the designated Grievance Officer mentioned below in writing or through email to:  manoj@kuberpe.com
              <br /> <br />
            </li>
            <li>
            Our Grievance Officer will take note of your query or complaint as soon possible. You acknowledge that this process might take some time and agree to not hold us liable for any reasonable delays. We will get back to you as soon as possible.
              <br /> <br />
            </li>
          </ol>
        </div>

        {/* 12.GOVERNING LAW & JURISDICTION */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
          12. GOVERNING LAW & JURISDICTION <br /> <br />
          </h2>
          
          <ol className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[2rem] font-poppinsRegular list-decimal">
            <li>
            Applicable law: The KuberPe Platform, our services, these Terms, and all transactions entered on through the KuberPe Platform between the user and us will be governed and construed in accordance with the applicable laws of India.
              <br /> <br />
            </li>
            <li>
            Jurisdiction: You agree that all claims, differences and disputes arising under these Terms or from your use of the KuberPe Platform are subject to the exclusive and competent jurisdiction of the courts in Delhi. However, if the Company thinks fit,
              <br /> <br />
              <h2 className="text-xl">
            it may institute proceedings against you in any other court or tribunal having appropriate jurisdiction.
          </h2>
            </li>
           
          </ol>
        </div>

        {/* 13.FORCE MAJEURE */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
          13. FORCE MAJEURE<br /> <br />
          </h2>
          <h2 className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-5 font-poppinsRegular">
          Without limiting anything in these Terms, under no circumstances shall the Company be held liable for any damage, loss, or suspension of services on the KuberPe Platform, resulting directly or indirectly from acts of nature, forces, or causes beyond its reasonable control, including, without limitation, internet failures, computer equipment failures, telecommunication equipment failures, or any other government regulations, floods, pandemic, lockdowns, storms, electrical failure, civil disturbances, riots.
          </h2>
        </div>

        {/* 14.LIMITATION OF LIABILITY */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
          14. LIMITATION OF LIABILITY  <br /> <br />
          </h2>
          
          <ol className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[2rem] font-poppinsRegular list-decimal">
            <li>
            Availability in other jurisdictions: The Company accepts no liability whatsoever, direct or indirect for non-compliance with the applicable laws of any country other than that of India. In case the KuberPe Platform is accessed or used, or any of our services can be availed from a country outside India, it will not obligate us to comply with the laws of such country or jurisdiction.
              <br /> <br />
            </li>
            <li>
            Timelines to make a claim: To the extent permitted by applicable law, any claim or cause of action arising out of or related to the access or use of the KuberPe Platform, our services, or these terms must be filed within 3 (three) months from such claim or cause of action arose, failing which such claim will be forever barred and deemed to be waived.
              <br /> <br />
            </li>
          </ol>
        </div>

        {/* 15.MISCELLANEOUS */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
          15. MISCELLANEOUS  <br /> <br />
          </h2>
          
          <ol className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[2rem] font-poppinsRegular list-decimal">
            <li>
            Notices: We will send notices and communications to you via. e-mail, letter, telephone, SMS, or any other means that we deem fit, to the address available in our records. The notice or communications will be deemed communicated once it is delivered from our personnel or automated systems. We may also, at our sole discretion, publish notices of general nature, which are applicable to all users and have the same effect as a notice served individually.
              <br /> <br />
            </li>
            <li>
            Dispute resolution: If any dispute arises between the user and the Company, in connection with validity, interpretation, implementation or alleged material breach of these Terms or the use of the KuberPe Platform, both parties will shall endeavour to settle such dispute amicably. In case of failure to resolve the dispute, the Company will be entitled to refer the dispute to arbitration. The arbitration shall be conducted by a sole arbitrator appointed by the Company. The arbitration proceedings will be in English and shall be conducted in Karnataka. It will be governed by the provisions of the Indian Arbitration and Conciliation Act 1996, or any statutory modification as may be then in force.
              <br /> <br />
            </li>
            <li>
            No Waiver: Unless waived or specifically agreed in writing by the Company, no action or inaction by us under these Terms will constitute a waiver of our rights or remedies available to us under applicable law.
              <br /> <br />
            </li>
            <li>
            Severability If any provision of these Terms is determined to be legally unenforceable or invalid, the remaining provisions will continue in effect. The Company will substitute a provision that most closely approximates the effect and intent of the invalid provision.
              <br /> <br />
            </li>
            <li>
            Assignment: You cannot assign or otherwise transfer these Terms, or any rights granted here to any third-party. Our rights, however, under these Terms are freely transferable to any third party without the requirement of seeking your consent.
              <br /> <br />
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Terms;
