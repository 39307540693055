import React, { useState } from "react";
import Navbar from "../components/Navbar";
import DailyFaq from "../components/DailyFaq";
import BlogDGold from "../components/BlogDGold";
import KuberPeNews from "../components/KuberPeNews";
import Mask from "../assets/images/Mask Group.svg";
import secure from "../assets/images/secure.svg";
import buy from "../assets/images/buy.svg";
import Marquee from "react-fast-marquee";
import sumitGupta from "../assets/images/Sumit Gupta.jpg";
import saurabhRai from "../assets/images/Saurabh Rai.jpg";
import dailsaving1 from "../assets/images/dailysaving1.png";
import dailsaving2 from "../assets/images/dailysaving2.png";
import { IoCloseSharp } from "react-icons/io5";

const DailySavings = () => {
  const [showPopup, setShowPopup] = useState(false);

  const openPlayStore = () => {
    // URL for your app on the Play Store
    const playStoreUrl = "http://app.kuberpe.com/refer/SRK892";
    window.open(playStoreUrl, "_blank");
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  return (
    <div className="bg-richblack-900  text-white font-poppinsRegular">
      <Navbar backgroundColour={"bg-richblack-900"} />

      {/* Save every day 1st section */}
      <div className="pt-10">
        <div className="items-center flex flex-col gap-3  justify-center">
          <h2 className="text-[#717171] text-xl font-poppinsMedium">
            Daily Savings
          </h2>
          <h1 className="font-poppinsBold md:text-4xl text-2xl text-center ">
            Save every day,
          </h1>{" "}
          <h1 className="text-[#FFDA2D] font-poppinsBold md:text-4xl text-2xl text-center ">
            {" "}
            automatically.
          </h1>
        </div>

        <div className=" bg-custom-color1 text-white my-24 lg:w-[85%] w-[90%] m-auto rounded-3xl py-20">
          <h1 className="font-poppinsBold md:text-4xl text-2xl text-center">
            Before you know, your{" "}
            <span className="text-[#FFDA2D]"> savings will grow.</span>
          </h1>

          <div class="items-center flex flex-col px-4 md:flex-row gap-4 justify-around py-20">
            <div className="p-5 w-80 md:h-48 rounded-lg bg-[#2e2942] items-center flex flex-row gap-2 md:flex-col justify-center">
              <img
                src={Mask}
                alt="Mask Group"
                className=""
                style={{ objectFit: "contain" }}
              />
              <h2 className="text-xl font-medium md:text-center">
                <span className="text-[#7745ff]">Buy,sell or transfer</span>{" "}
                with utmost ease
              </h2>
            </div>
            <div className="p-5 w-80 md:h-48  rounded-lg bg-[#2e2942] items-center flex flex-row gap-2 md:flex-col justify-center">
              <img
                src={secure}
                alt="secure"
                className=""
                style={{ objectFit: "contain" }}
              />
              <h2 className="text-xl font-medium md:text-center">
                It is <span className="text-[#7745ff]">totally secure</span> and
                fraud resistant
              </h2>
            </div>
            <div className="p-5 w-80 md:h-48 rounded-lg bg-[#2e2942] items-center flex flex-row gap-2 md:flex-col justify-center">
              <img
                src={buy}
                alt="buy"
                className=""
                style={{ objectFit: "contain" }}
              />
              <h2 className="text-xl font-medium h-[50%] md:text-center">
                Anyone can buy, starting{" "}
                <span className="text-[#7745ff]">as low as ₹10</span>
              </h2>
            </div>
          </div>

          <h1 className="font-poppinsBold md:text-xl text-2xl text-center">
            Want to know how your <br />
            <span className="text-[#FFDA2D]"> gold savings</span> will perform?
          </h1>

          <div className="h-12 w-48 m-auto mt-10">
            <button
              onClick={openPlayStore}
              className="text-lg rounded-lg bg-custom-color1 border border-[#7745ff] h-[100%] w-[100%] hover:bg-[#7745ff] active:bg-[#7745ff]"
            >
              Calculate now
            </button>
          </div>
        </div>
      </div>

      {/* how it work */}
      <div className="lg:w-[65%] w-[95%] m-auto">
        <h1 className="font-poppinsBold md:text-4xl text-2xl text-center">
          Here’s how it <span className="text-[#FFDA2D]">works.</span>
        </h1>

        <div className="items-center flex flex-col md:flex-row justify-between gap-14 font-poppinsRegular mt-10 ">
          <div className=" p-8 md:w-[50%] w-[90%] md:h-[450px] rounded-3xl bg-custom-color1">
            <img src={dailsaving1} alt="dailysavings1.png" className=" h-[65%] w-[100%]"  />
            <h2 className="text-xl font-poppinsSemibold py-3 text-center text-yellow-300">
              Step 1
            </h2>
            <h2 className="text-2xl text-blue-200 font-poppinsSemibold text-center">
              Set desired amount
            </h2>
            <h1 className="text-center">
              Jar recommends at least <br />
              Rs. 50/day.
            </h1>
          </div>
          <div className=" p-8 md:w-[50%] w-[90%] md:h-[450px] rounded-3xl bg-custom-color1">
            <img src={dailsaving2} alt="" className=" h-[65%] w-[100%]"/>
            <h2 className="text-xl font-poppinsSemibold py-3 text-center text-yellow-300">
              Step 2
            </h2>
            <h2 className="text-2xl text-blue-200 font-poppinsSemibold text-center">
              Setup UPI mandate
            </h2>
            <h1 className="text-center">
              Set up mandate on your UPI App to <br />
              automate your savings.
            </h1>
          </div>
        </div>
      </div>

      {/* ready to save */}
      <div className="bg-custom-color1 text-white rounded-3xl py-20 mt-28">
        <h1 className="font-poppinsBold md:text-xl text-sm text-center">
          Ready to Save?
        </h1>

        <div className="h-12 w-48 m-auto mt-10">
          <button
            onClick={openPlayStore}
            className="text-lg rounded-lg bg-custom-color1 border border-[#7745ff] h-[100%] w-[100%] hover:bg-[#7745ff] active:bg-[#7745ff]"
          >
            Start Saving
          </button>
        </div>

        <div className="bg-[#494364] md:w-[70%] w-[90%] m-auto mt-20 py-11 rounded-3xl">
          <div className=" flex md:justify-center  md:flex-row pl-5">
            <img
              src={sumitGupta}
              className=" rounded-full h-16  w-16 left-4 relative bg-white   "
              alt=""
              style={{ objectFit: "fill" }}
            />

            <img
              src={saurabhRai}
              className=" rounded-full h-16 w-16  bg-white   "
              alt=""
              style={{ objectFit: "fill" }}
            />
          </div>

          <div className="m-auto w-[80%] md:text-center text-left">
            <h1 className="font-poppinsBold md:text-4xl text-2xl mt-5">
              Meet <span className="text-[#FFDA2D]">Sumit</span>
            </h1>

            <h2 className="text-xl mt-5">
              The thing with saving on KuberPe is that you have to stay the
              course. I saved consistently for 6 months and now I have 6gms of
              gold.
            </h2>

            <h2 className="text-2xl mt-11">Sumit Gupta</h2>
            <h2 className="text-md text-richblack-200">Executive Producer</h2>
          </div>
        </div>

        <div className=" m-auto  w-[100%]   pt-20 md:w-[85%]">
          <h1 className=" md:text-2xl text-sm text-center pb-10">
            The partners we rely on
          </h1>
          <Marquee pauseOnHover speed={100}>
            <div className="w-[95%] flex gap-16 justify-center items-center">
              <div className=" md:h-24  h-24 rounded-lg border border-[#866A04] flex justify-center items-center ">
                <img
                  src="./images/startupindiaKuberPe.png"
                  className="  bg-white h-[100%] w-[100%] rounded-lg  "
                  alt=""
                />
              </div>

              <div className="  md:h-24  h-24 rounded-lg border border-[#866A04] flex justify-center items-center ">
                <img
                  src="./images/decentroKuberPe.png"
                  className=" bg-white h-[100%] w-[100%] rounded-lg  "
                  alt=""
                />
              </div>

              <div className="  md:h-24  h-24 rounded-lg border border-[#866A04] flex justify-center items-center ">
                <img
                  src="./images/lendboxKuberPe.png"
                  className="  bg-white h-[100%] w-[100%] rounded-lg  "
                  alt=""
                />
              </div>

              <div className="  md:h-24  h-24 rounded-lg border border-[#866A04] flex justify-center items-center ">
                <img
                  src="./images/phonepeKuberPe.png"
                  className=" bg-white h-[100%] w-[100%] rounded-lg  "
                  alt=""
                />
              </div>

              <div className="  md:h-24  h-24  rounded-lg border border-[#866A04] flex justify-center items-center ">
                <img
                  src="./images/augmontKuberPe.png"
                  className="  bg-white h-[100%] w-[100%] rounded-lg  "
                  alt=""
                />
              </div>
            </div>
          </Marquee>
        </div>
      </div>

      {/* Daily saving and amazing */}
      <div className="lg:w-[85%] m-auto relative top-32">
        <h2 className="md:text-5xl text-3xl font-poppinsBold text-white text-center">
          Daily savings, <span className="text-[#e5e557]">so amazing!</span>
        </h2>
        <div class="items-center flex flex-col md:flex-row gap-4 justify-around pt-16">
          <div className="p-5 w-60 md:h-48 rounded-lg bg-[#2e2942] items-center flex flex-row gap-2 md:flex-col justify-center">
            <img
              src={Mask}
              alt="Mask Group"
              className=""
              style={{ objectFit: "contain" }}
            />
            <h2 className="text-xl font-medium md:text-center">
              <span className="text-[#7745ff]">Buy,sell or transfer</span> with
              utmost ease
            </h2>
          </div>
          <div className="p-5 w-60 md:h-48  rounded-lg bg-[#2e2942] items-center md:flex flex-row gap-2 md:flex-col justify-center hidden">
            <img
              src={secure}
              alt="secure"
              className=""
              style={{ objectFit: "contain" }}
            />
            <h2 className="text-xl font-medium md:text-center">
              It is <span className="text-[#7745ff]">totally secure</span> and
              fraud resistant
            </h2>
          </div>
          <div className="p-5 w-60 md:h-48 rounded-lg bg-[#2e2942] items-center md:flex flex-row gap-2 md:flex-col justify-center hidden">
            <img
              src={buy}
              alt="buy"
              className=""
              style={{ objectFit: "contain" }}
            />
            <h2 className="text-xl font-medium h-[50%] md:text-center">
              Anyone can buy, starting{" "}
              <span className="text-[#7745ff]">as low as ₹10</span>
            </h2>
          </div>
          <div className="p-5 w-60 md:h-48 rounded-lg bg-[#2e2942] items-center flex flex-row gap-2 md:flex-col justify-center">
            <img
              src={buy}
              alt="buy"
              className=""
              style={{ objectFit: "contain" }}
            />
            <h2 className="text-xl font-medium h-[50%] md:text-center">
              Anyone can buy, starting{" "}
              <span className="text-[#7745ff]">as low as ₹10</span>
            </h2>
          </div>
        </div>
      </div>

      {/* profile */}
      <div
        className="h-[400px] lg:w-[35%] md:w-[50%] w-[90%] rounded-2xl relative m-auto top-48"
        style={{
          backgroundImage: "url('./images/Sumit Gupta.jpg'",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* <!-- Content goes here --> */}
        <div className="items-center flex flex-col justify-center text-black text-center h-[100%] w-[100%] rounded-2xl">
          <div className="top-28 relative w-[100%]">
            <h2 className="lg:text-3xl text-2xl font-poppinsBold text-white text-center w-[100%]">
              Want to Know more?
            </h2>
            {/* #4126a3 */}
            <button
              onClick={togglePopup}
              className="bg-richblue-700 font-poppinsSemibold text-white w-40 h-14 text-xl mt-5 bg-opacity-60 rounded-lg hover:bg-[#336588]"
            >
              Watch Video
            </button>

            {showPopup && (
              <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-10">
                <div className="">
                  <div className="relative flex md:justify-end justify-center items-center text-white md:items-end">
                    <button onClick={togglePopup}>
                      <IoCloseSharp />
                    </button>
                  </div>
                  <iframe
                    width="800"
                    height="450"
                    src="https://www.youtube.com/embed/D5CzZWhtjHw?si=ZqSdNsW01Y0yTfmA"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* kuberpe in news */}
      <div className="">
        <KuberPeNews />
      </div>

      {/* blog section */}
      <div>
        <BlogDGold />
      </div>

      {/* last section faq */}
      <div>
        <DailyFaq />
      </div>
    </div>
  );
};

export default DailySavings;
