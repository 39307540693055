import React, { useState } from 'react';
import Chart from 'chart.js/auto';
import { getRelativePosition } from 'chart.js/helpers';
// import { Bar } from 'react-chartjs-2';
// import { Line } from 'react-chartjs-2';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList, LineChart, Line } from 'recharts';

const year1 = [
  {
    name:"KuberPe P2P",
    name1: "Nifty 50",
    value: 12.00
  },
  {
    name:"Bonds",
    name1: "MF",
    value: 7.80
  },
  {
    name:"FDs",
    name1: "ELSS",
    value: 6.60
  },
  {
    name:"PPF",
    name1: "P2P",
    value: 7.10
  }
]
const year3 = [
  {
    name:"KuberPe P2P",
    name1: "Nifty 50",
    value: 12.00
  },
  {
    name:"Bonds",
    name1: "MF",
    value: 4.00
  },
  {
    name:"FDs",
    name1: "ELSS",
    value: 7.00
  },
  {
    name:"PPF",
    name1: "P2P",
    value: 7.10
  }
]

const year5 = [
  {
    name:"KuberPe P2P",
    name1: "Nifty 50",
    value: 12.00
  },
  {
    name:"Bonds",
    name1: "MF",
    value: 8.00
  },
  {
    name:"FDs",
    name1: "ELSS",
    value: 7.70
  },
  {
    name:"PPF",
    name1: "P2P",
    value: 7.10
  }
]

const year10 = [
  {
    name:"KuberPe P2P",
    name1: "Nifty 50",
    value: 12.00
  },
  {
    name:"Bonds",
    name1: "MF",
    value: 8.50
  },
  {
    name:"FDs",
    name1: "ELSS",
    value: 7.00
  },
  {
    name:"PPF",
    name1: "P2P",
    value: 7.10
  }
]
// const year3 = [12.00,4.00,7.00,7.10]
// const year5 = [12.00,8.00,7.00,7.10]
// const year10 = [12.00,8.50,7.00,7.10]

const Graph = () => {
  const [isDebt, setIsDebt] = useState(true);
  const [selectedYear, setSelectedYear] = useState("1Y");

  return (
    <div className='md:h-[600px] h-[850px] w-[100%] bg-richblack-900 flex items-center justify-center'>
      <div className='h-[90%] w-[90%] flex md:flex-row flex-col items-center justify-between'>
        <div className='md:h-[100%] h-[45%] w-[100%] md:w-[45%] flex  items-center justify-center'>
          <div className='md:h-[70%] md:w-[80%] h-[100%] w-[100%] md:mt-[2rem] xs:mt-[1rem]'>
            <p className='md:text-4xl lg:text-5xl text-3xl font-poppinsRegular font-bold leading-tight text-[#ffffff]'>How does <br /><span className='text-[#20afec]'> KuberPe P2P </span> <br /> fare in comparison to other assets?</p>
            <p className='text-[#f2f2f2] font-poppinsRegular md:text-xl text-lg mt-[1.5rem]'>P2P as an asset class offers a balanced combination of high returns, with minimised risk for your money.</p>
          </div>
        </div>
        <div className='md:h-[80%] h-[65%] w-[100%] md:w-[45%]'>
          <div className='md:h-[12%] h-[14%] w-[100%] flex items-center justify-center '>
            <div onClick={() => setIsDebt(!isDebt)} className={`border-2 cursor-pointer ${isDebt ? 'bg-[#151c23]' : null} border-[#f2f2f2] text-[#f2f2f2] text-xl text-center md:p-[0.7rem] p-[1rem] rounded-l-xl h-[100%] md:w-[40%] w-[50%]`}>Debt</div>
            <div onClick={() => setIsDebt(!isDebt)} className={`border-2 cursor-pointer ${!isDebt ? 'bg-[#151c23]' : null} border-[#f2f2f2] text-[#f2f2f2] text-xl text-center md:p-[0.7rem]  p-[1rem]  rounded-r-xl h-[100%] md:w-[40%] w-[50%]`}>Equity</div>
          </div>
            
            <div className='h-[320px] w-[100%] mt-[1rem]'>
              {isDebt ? <ResponsiveContainer width={'100%'} height={300}>
                <BarChart
                  width={600}
                  height={300}
                  data={selectedYear === "1Y" ? year1 : (selectedYear === "3Y" ? year3 : (selectedYear==="5Y" ? year5 : year10))}
                  margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
                  barSize={40}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name"/>
                  <YAxis tickFormatter={(value) => value + '%'}/>
                  <Legend />
                  <Bar dataKey="value" fill="#8884d8" background={{ fill: 'white' }}>
                    <LabelList position="top" fill='#20afec' formatter={(value) => value + '%'}/>
                  </Bar>
                </BarChart>
              </ResponsiveContainer> :
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart
                    width={600}
                    height={300}
                    data={selectedYear === "1Y" ? year1 : (selectedYear === "3Y" ? year3 : (selectedYear==="5Y" ? year5 : year10))}
                    margin={{ top: 5, right: 30, left: 20,  bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name1" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              }
            </div>
            <div className='w-[100%] md:h-[10%] h-[10%] flex items-center justify-center'>
              <div className='md:w-[90%] w-[100%] h-[100%] flex items-center justify-between'>
                <div onClick={() => setSelectedYear("1Y")} className={`${selectedYear === "1Y" ? 'bg-[#FFFF]' : 'bg-[#111827]'} ${selectedYear === "1Y" ? 'text-black' : 'text-white'} cursor-pointer font-semibold h-[95%] w-[20%] rounded-2xl  flex items-center justify-center`}>1Y</div>
                <div onClick={() => setSelectedYear("3Y")} className={`${selectedYear === "3Y" ? 'bg-[#FFFF]' : 'bg-[#111827]'} ${selectedYear === "3Y" ? 'text-black' : 'text-white'} cursor-pointer font-semibold h-[95%] w-[20%] rounded-2xl bg-[#111827] flex items-center justify-center`}>3Y</div>
                <div onClick={() => setSelectedYear("5Y")} className={`${selectedYear === "5Y" ? 'bg-[#FFFF]' : 'bg-[#111827]'} ${selectedYear === "5Y" ? 'text-black' : 'text-white'} cursor-pointer font-semibold h-[95%] w-[20%] rounded-2xl bg-[#111827] flex items-center justify-center`}>5Y</div>
                <div onClick={() => setSelectedYear("10Y")} className={`${selectedYear === "10Y" ? 'bg-[#FFFF]' : 'bg-[#111827]'} ${selectedYear === "10Y" ? 'text-black' : 'text-white'} cursor-pointer font-semibold h-[95%] w-[20%] rounded-2xl bg-[#111827] flex items-center justify-center`}>10Y</div>
              </div>
            </div>
            <div className='w-[100%] flex items-center justify-around'>
              {isDebt ? <p className='text-white mt-[1.5rem] text-xs font-poppinsRegular lg:w-[90%] w-[100%]'>Annual returns (CAGR) for different asset classes shown above Returns for all asset classes across tenure as on 30th Oct 2023. Bonds - Yield on govt bonds FDs - HDFC FD rates</p> : 
                <p className='text-white mt-[1.5rem] text-xs font-poppinsRegular lg:w-[90%] w-[100%]'>Overall returns for different asset classes shown above The following mutual funds are used to indicate the returns in different asset classes:
                  Nifty 50 - HDFC Nifty 50 Index Fund
                  MF - HDFC Large and Mid Cap Fund
                  ELSS - UTI ELSS Tax saver fund
                </p>
              }
            </div>
        </div>
      </div>
    </div>
  )
}

export default Graph