import React from "react";
import { useEffect, useState } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LazyImage from "../components/LazyImage";

const Partnerslider = () => {
  const [isAutoplay, setIsAutoplay] = useState(true);
  const [hideArrows, setHideArrows] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Disable autoplay for medium (md) screens and above
      setIsAutoplay(window.innerWidth < 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setHideArrows(window.innerWidth >= 768); // Hide arrows for medium and larger screens
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: isAutoplay,
    autoplaySpeed: 3000,
    arrows: !hideArrows, // Show arrows unless hideArrows is true

    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          prevArrow: false, // Hide arrows for md screens
          nextArrow: false, // Hide arrows for md screens
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          prevArrow: false, // Hide arrows for md screens
          nextArrow: false, // Hide arrows for md screens
        },
      },
    ],
  };
  return (
    <div>
      <div className="   pt-20 bg-custom-color2 text-white">
        <div className="w-[85%]  m-auto font-poppinsRegular">
          {/* Paragraph Section */}
          <div className=" flex flex-col gap-3 items-center justify-center">
            <h1 className="font-bold md:text-5xl md:h-14 text-3xl font-poppinsRegular">
              Benefits
            </h1>

            <h1 className="font-bold text-center md:text-4xl text-xl font-poppinsRegular">
              Rewards for your savings
            </h1>
            <h2 className="text-sm md:text-xl text-center text-gray-400 font-poppinsRegular">
              Your clients also stand a chance to earn rewards in tickets every
              week
            </h2>
          </div>

          <div className="  mt-10">
            <Slider {...settings}>
              {/* Content Section */}
              <div className=" flex justify-center w-[85%]   text-white  mr-12 ">
                <img
                  src="./images/get commission reffert program@2x.png"
                  alt="slide 1"
                  className="rounded-lg   object-contain h-36 m-auto"
                  style={{objectFit:"contain"}}
                />

{/* <LazyImage src={"./images/get commission reffert program@2x.png"} alt={"kuberplus Grow ldpi"}/> */}


                <div className="text-center w-60 md:w-72 m-auto mt-4 ">
                  <p className="font-bold md:text-xl text-xl font-poppinsRegular">
                    Get Monthly Commissions
                  </p>
                  <p className="font-poppinsRegular">
                    {" "}
                    Get monthly commissions on every investment that your client
                    makes
                  </p>
                </div>
              </div>
              <div className="  flex justify-center  text-white    ">
                <img
                  src="./images/dashbord@2x.png"
                  alt="slide 2"
                  className="rounded-lg    object-contain  h-36 m-auto "
                  style={{objectFit:"contain"}}
                />
                <div className="text-center w-60 md:w-72 m-auto mt-4 ">
                  <p className="font-bold md:text-xl text-xl font-poppinsRegular">
                    Performance Dashboard
                  </p>
                  <p className="font-poppinsRegular">
                    Get access to your own dashboard to track your activity
                  </p>
                </div>
              </div>
              <div className="  flex justify-center  text-white    ">
                <img
                  src="./images/get reward@2x.png"
                  alt="slide 3"
                  className="rounded-lg  object-contain h-36 m-auto "
                  style={{objectFit:"contain"}}
                />
                <div className="text-center w-60 md:w-72 m-auto mt-4 ">
                  <p className="font-bold md:text-xl text-xl mt-4 font-poppinsRegular">
                    Rewards for your savings
                  </p>
                  <p className="font-poppinsRegular">
                    Your clients also stand a chance to earn rewards in tickets
                    every week
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partnerslider;
