import React from "react";

const Grievance = () => {
  return (
    <div className="bg-richblack-900  text-white">
      <div className="w-[85%] m-auto flex flex-col gap-9 py-20 font-poppinsRegular">
        <h1 className="md:text-6xl text-2xl font-poppinsBold md:h-32 items-center flex justify-center">
          Grievance Redressal Mechanism
        </h1>
        <div className=" md:w-[80%] m-auto">
        <h2 className="text-left font-poppinsRegular md:text-xl py-5">
          You can email us at <span className="text-[#2E8BFF]">support@kuberpe.com</span> . We assure you that your
          grievance will be addressed on priority.
        </h2>
        <h1 className="text-center font-poppinsSemibold md:text-3xl text-xl py-5">
          Nodal Grievance Redressal Official:
        </h1>
        <h2 className="text-left font-poppinsRegular md:text-xl py-5">
          Our customer services team works diligently to ensure that all your
          complaints are resolved. However, if for any reason you haven’t
          received any response within 07 business days, we request that you
          reach out to our Nodal Grievance Redressal Official. You can reach out
          to us any time between 10:00 AM to 06:00 PM on weekdays (Monday –
          Friday) except on public holidays. We assure you that our team will do
          their best to address your issue.
        </h2>
        <h2 className="text-left font-poppinsRegular md:text-xl py-5">
          Name: Mr. Rishikesh Kumar Ray
          <br />
          Address: C-1/181 G/Floor KH. No-1849 C-1 Block Phase-IV South Delhi –
          110047.
          <br />
          Email ID: <span className="text-[#2E8BFF]">rishikesh@kuberpe.com</span> 
          <br />
          Phone Number:  +91 9569054881{" "}
        </h2>
        <h1 className="text-center font-poppinsSemibold md:text-3xl text-xl py-5">
          Augmont Grievance Redressal Official:
        </h1>
        <h2 className="text-left font-poppinsRegular md:text-xl py-5">
          Name: Mr. Anand Amrut Rathod
          <br />
          Address: 504, 5th Floor, B&C Block, Trade Link, Kamala Mills, Lower
          Parel Maharashtra-400013
          <br />
          Email ID: mailto: <span className="text-[#2E8BFF]">anand.rathod@augmont.com</span> 
          <br />
          Phone Number:  022-42444830 <br />
        </h2>


        <h1 className="text-center font-poppinsSemibold md:text-3xl text-xl py-5">
          
              Lendbox Grievance Redressal Official:

        </h1>
        <h2 className="text-left font-poppinsRegular md:text-xl py-5">
          Name: Mr. Ekmmeet Singh
          <br />
          Address: 2nd Floor,7A Lajpat Nagar-4, Ring Road Delhi-110024
          <br />
          Email ID: mailto: <span className="text-[#2E8BFF]">mailto:grivances@lendbox.in</span> 
          <br />
          Phone Number: +91 7291029298  <br />
        </h2>
        </div>
      </div>
    </div>
  );
};

export default Grievance;
