import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import { FaApple } from "react-icons/fa6";
import Navbar from "../components/Navbar";
import Presentingold1 from "../assets/images/Presentingold1.png";

const GoldPlus = () => {
  const [showTooltip, setShowTooltip] = useState(false);

  const openPlayStore = () => {
    // URL for your app on the Play Store
    const playStoreUrl = "http://app.kuberpe.com/refer/SRK892";
    window.open(playStoreUrl, "_blank");
  };
  return (
    <div className="bg-custom-color2 text-white font-poppinsRegular">
      <Navbar backgroundColour={'bg-custom-color1'}/>
      {/* top header box */}
      <div className="h-max-content bg-custom-color1 flex flex-col  items-center justify-center w-[100%]">
        <div className="md:w-[85%] w-[100%] mt-10 mb-24 md:h-[92.7%] h-[70%] flex items-center justify-center">
          <div className="h-[100%] flex flex-col items-center jsutify-center md:w-[60%] w-[100%]">
            <p className="md:text-5xl text-3xl text-white font-poppinsRegular text-center font-bold">
              Gold Plus
            </p>
            <p className="md:text-3xl text-xl mt-[2rem] text-white tracking-wider font-poppinsRegular text-center">
              Grow your wealth with the <br /> power of compounding
            </p>
            <p className="mt-[2rem] text-white font-bold md:text-2xl text-xl font-poppinsRegular tracking-wider text-center">
              FMPP-MONTHLY INCOME <br />
              Earn upto <span className="text-[yellow]">14%</span> p.a.
            </p>
            {/* <p className="mt-[2rem] text-white font-bold md:text-2xl text-xl font-poppinsRegular tracking-wider text-center">
              Earn upto <span className="text-[yellow]">14%</span> p.a.
            </p> */}
            <div className="md:w-[65%] w-[100%] mt-[2rem] items-center flex  justify-center gap-[1rem]">
              <div className="flex gap-2 justify-center items-center">
                <div className="h-[10px] w-[10px] rounded-[50%] bg-[yellow]"></div>
                <p className="font-semibold tracking-wide md:text-md text-xs text-white font-poppinsRegular">
                  Monthly
                </p>
              </div>
              <div className="flex gap-2 justify-center items-center">
                <div className="h-[10px] w-[10px] rounded-[50%] bg-[yellow]"></div>
                <p className="font-semibold tracking-wide md:text-md text-xs  text-white font-poppinsRegular">
                  Passive
                </p>
              </div>
              <div className="flex gap-2 justify-center items-center">
                <div className="h-[10px] w-[10px] rounded-[50%] bg-[yellow]"></div>
                <p className="font-semibold tracking-wide md:text-md text-xs  text-white font-poppinsRegular">
                  Predictable Performance
                </p>
              </div>
            </div>
            <div className="md:w-[65%] w-[100%] mt-[2rem] flex items-center justify-center gap-[1rem]">
              <button
                onClick={openPlayStore}
                class="bg-blue-900 text-white px-16 py-2 rounded-lg text-lg font-semibold shadow-lg hover:bg-blue-800"
              >
                Start Investing
              </button>
            </div>
            <p className="mt-[2rem] text-white font-bold text-2xl font-poppinsRegular tracking-wider text-center">
              RBI Certified NBFC-P2P
            </p>
            <div className="md:w-[50%] w-full flex  justify-center h-100% mt-10">
              <img
                src="./images/Gold Plus - FMPP-MONTHLY INCOME.png"
                alt="twsimage"
                className="h-56 w-72"
                style={{objectFit:"contain"}}
              />
            </div>
          </div>
        </div>
      </div>

      {/* portfolio section */}
      <div className="bg-richblack-900 text-white py-24">
        <h1 className="text-center font-poppinsBold md:text-3xl text-xl">
          Build a strong portfolio, without compromising on returns
        </h1>
        <div className="md:w-[70%] w-[90%] font-poppinsRegular m-auto grid md:grid-cols-2 grid-cols-1 gap-4 md:py-14 py-8">
          <div className="items-center bg-[#4e4669] flex gap-5 justify-center  rounded-3xl text-white p-8">
            <img className="w-10 h-10" src="./images/icon1.svg" alt="icon1" style={{objectFit:"contain"}}/>
            <h2 className="md:text-xl text-sm">
              Grow your money with the Power of Compounding.
            </h2>
          </div>
          <div className="items-center bg-[#4e4669] flex gap-5 justify-center  rounded-3xl text-white p-8">
            <img className="w-10 h-10" src="./images/icon2.svg" alt="icon2" style={{objectFit:"contain"}}/>
            <h2 className="md:text-xl text-sm">
              Your investment is deployed into loan portfolios
            </h2>
          </div>
          <div className="items-center bg-[#4e4669] flex gap-5 justify-center  rounded-3xl text-white p-8">
            <img className="w-10 h-10" src="./images/icon3.svg" alt="icon3" style={{objectFit:"contain"}}/>
            <h2 className="md:text-xl text-sm">
              Repayments are reinvested to deliver accelerated returns
            </h2>
          </div>
          <div className="items-center bg-[#4e4669] flex gap-5 justify-center  rounded-3xl text-white p-8">
            <img className="w-10 h-10" src="./images/icon4.svg" alt="icon4" style={{objectFit:"contain"}}/>
            <h2 className="md:text-xl text-sm">
              Loans repay principal & interest every month.
            </h2>
          </div>
        </div>

        <div className="items-center flex justify-between md:w-[60%] w-[90%] m-auto md:text-xl  font-poppinsRegular">
          <div className="items-center flex justify-center gap-2">
            <img src="./images/check.svg" alt="" className="" style={{objectFit:"contain"}}/>
            <h5 className="text-xs">Strong Diversification</h5>
          </div>
          <div className="items-center flex justify-center gap-2">
            <img src="./images/check.svg" alt="" className="" style={{objectFit:"contain"}}/>
            <h5 className="text-xs">Secure Transactions</h5>
          </div>
          <div className="items-center flex justify-center gap-2">
            <img src="./images/check.svg" alt="" className="" style={{objectFit:"contain"}}/>
            <h5 className="text-xs">High Quality Loans</h5>
          </div>
        </div>
      </div>

      {/* risk category */}
      <div className="md:w-[70%] m-auto py-24">
        <h1 className="text-center font-poppinsBold md:text-3xl text-xl ">
          Select your risk category
        </h1>
        <div className="items-center flex flex-col md:flex-row justify-between gap-14 font-poppinsRegular mt-10">
          <div className=" p-8 md:w-[50%] w-[90%] rounded-3xl bg-custom-color1">
            <h2 className="text-md font-poppinsSemibold">Earn upto</h2>
            <h2>
              <span className="text-4xl text-yellow-200 font-poppinsBold">
                9%
              </span>
              p.a.
            </h2>
            <h2 className="text-2xl font-poppinsSemibold py-3">Growth</h2>
            <h2 className="text-2xl text-blue-200 font-poppinsSemibold">
              Aggressive
            </h2>
            <ul className="list-disc text-md p-8">
              <li>
                Your funds are invested in loans with Medium credit ratings.
              </li>
              <li>Repayments are reinvested to deliver accelerated returns.</li>
            </ul>
          </div>
          <div className=" p-8 md:w-[50%] w-[90%] rounded-3xl bg-custom-color1">
            <h2 className="text-md font-poppinsSemibold">Earn upto</h2>
            <h2>
              <span className="text-4xl text-yellow-200 font-poppinsBold">
                11%
              </span>
              p.a.
            </h2>
            <h2 className="text-2xl font-poppinsSemibold py-3">Growth</h2>
            <h2 className="text-2xl text-blue-200 font-poppinsSemibold">
              Conservative
            </h2>
            <ul className="list-disc text-md p-8">
              <li>
                Your funds are invested in loans with High credit ratings.
              </li>
              <li>Repayments are reinvested to deliver accelerated returns.</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Start investing */}
      <div className="bg-custom-color1 text-white ">
      <div className="w-[85%] m-auto font-poppinsRegular py-24">
        <h1 className="text-center font-poppinsBold md:text-3xl text-xl pb-10">
          Start investing in a few simple steps
        </h1>

        <div className="items-center flex flex-col lg:flex-row gap-2 justify-between">
          <div className=" items-center flex lg:flex-col flex-row gap-4  rounded-2xl lg:bg-[#2e2942] lg:text-white text-white p-4 justify-center lg:h-52 h-24 lg:w-64 w-full text-center">
            <div className="h-[50%]">
              <div className="border lg:rounded-full lg:h-14 lg:w-14 h-10 w-10 text-xl items-center flex justify-center bg-[#2e2942] text-white">
                1
              </div>
              <div className="border h-20 w-0 m-auto items-center flex flex-col justify-center lg:hidden"></div>
            </div>
            <div className="lg:text-center text-left w-[100%] h-[50%]">
              <h2 className="lg:text-md font-poppinsSemibold">
                Setup Account
              </h2>
              <h1>KYC Verification - Aadhaar, PAN, Bank account</h1>
            </div>
          </div>
          <hr className="border w-20 lg:flex hidden " />

          <div className=" items-center flex lg:flex-col flex-row gap-4  rounded-2xl lg:bg-[#2e2942] lg:text-white text-white p-4 justify-center lg:h-52 h-24 lg:w-64 w-full text-center">
            <div className="h-[50%]">
              <div className="border lg:rounded-full lg:h-14 lg:w-14 h-10 w-10 text-xl items-center flex justify-center bg-[#2e2942] text-white">
                2
              </div>
              <div className="border h-20 w-0 m-auto items-center flex flex-col justify-center lg:hidden"></div>
            </div>
            <div className="lg:text-center text-left w-[100%] h-[50%]">
              <h2 className="lg:text-md font-poppinsSemibold">
                Select Plan & Risk Category
              </h2>
              <h1>
                Growth plan <span className="text-yellow-50 text-2xl">•</span>{" "}
                Income Plan
              </h1>
            </div>
          </div>
          <hr className="border w-20 lg:flex hidden" />

          <div className=" items-center flex lg:flex-col flex-row gap-4  rounded-2xl lg:bg-[#2e2942] lg:text-white text-white p-4 justify-center lg:h-52 h-24 lg:w-64 w-full text-center">
            <div className="h-[50%]">
              <div className="border lg:rounded-full lg:h-14 lg:w-14 h-10 w-10 text-xl items-center flex justify-center bg-[#2e2942] text-white">
                3
              </div>
              <div className="border h-20 w-0 m-auto items-center flex flex-col justify-center lg:hidden"></div>
            </div>
            <div className="lg:text-center text-left w-[100%] h-[50%]">
              <h2 className="lg:text-md font-poppinsSemibold">Add Money</h2>
              <h1>UPI or bank transfer</h1>
            </div>
          </div>
          <hr className="border w-20 lg:flex hidden" />

          <div className=" items-center flex lg:flex-col flex-row gap-4  rounded-2xl lg:bg-[#2e2942] lg:text-white text-white p-4 justify-center lg:h-52 h-24 lg:w-64 w-full text-center">
            <div className="h-[50%]">
              <div className="border lg:rounded-full lg:h-14 lg:w-14 h-10 w-10 text-xl items-center flex justify-center bg-[#2e2942] text-white">
                4
              </div>
            </div>
            <div className="lg:text-center text-left w-[100%] h-[50%]">
              <h2 className="lg:text-md font-poppinsSemibold">All Set</h2>
              <h1>E-Sign Terms and conditions</h1>
            </div>
          </div>
        </div>

        <div className="h-12 w-48 m-auto mt-10">
        
            <button onClick={openPlayStore}  className="text-lg rounded-md bg-[#7745ff] text-white border border-[#7745ff] h-[100%] w-[100%] hover:bg-[#7745ff] active:bg-[#7745ff]">
              Start Investing
            </button>
 
        </div>
      </div>
    </div>

      {/* growth with industry leading */}
      <div className="bg-custom-color1">
        <div className="w-[85%] m-auto pb-24">
          <div className=" items-center flex justify-between  flex-col  md:flex-row">
            {/* First Column (Paragraph Section) */}
            <div className="md:w-[52%]">
              <div className=" text-white text-2xl">
                <p>MAXIMIZE YOUR WEALTH</p>
              </div>
              <p className="text-black-700 my-6  text-3xl xs:text-center   text-white leading-12 md:text-5xl">
                Grow with
                <br /> <b className="text-yellow-100">industry leading</b>
                <br /> returns
              </p>
              {/* google play apple */}
              <div className="lg:w-[80%] w-[100%] flex gap-3 items-center cursor-pointer ">
                <div
                  onClick={openPlayStore}
                  className="h-16 text-white border rounded-lg flex items-center justify-center w-[50%]"
                >
                  <img
                    src="./images/playstore.png"
                    alt="google"
                    className="w-10 h-10"
                    style={{objectFit:"contain"}}
                  />
                  <div className="text-white leading-3">
                    <p className="text-sm text-gray-400">Get It On</p>
                    <p className="text-md">Google Play</p>
                  </div>
                </div>

                <div
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  className="h-16 border text-white rounded-lg flex items-center justify-center w-[50%]"
                >
                  <div className="text-white">
                    <FaApple style={{ height: "30px", width: "30px" }} />
                  </div>
                  <div className="text-white leading-3">
                    <p className="text-sm text-gray-400">Download on the</p>
                    <p className="text-md">App Store</p>
                  </div>
                </div>
                {showTooltip && (
                  <span className="absolute bg-[#7745ff] text-white text-md py-1 px-6 rounded-md mb-24 ml-80 opacity-100 transition-opacity duration-300">
                    Comming Soon....
                  </span>
                )}
              </div>
            </div>

            {/* Second Column (Image Section) */}
            <div className="md:w-[48%] w-full flex justify-end h-100%">
              <img src={Presentingold1} alt="twsimage" style={{objectFit:"contain"}} />
            </div>
          </div>
        </div>
      </div>

      {/* great investment? */}
      <div className="pt-24">
        <div>
          <h1 className="text-center font-poppinsBold md:text-3xl text-xl pb-10">
            What makes it a great investment?
          </h1>
        </div>

        <div className=" m-auto  w-[100%]   md:w-[85%]">
          <Marquee pauseOnHover speed={100}>
            <div className="grid grid-cols-4 lg:flex lg:flex-wrap  justify-items-center md:gap-10 gap-5 justify-center font-poppinsRegular md:m-auto ">
              <div className="bg-[#272239] rounded-2xl md:h-[350px] md:w-72 w-40 h-[150px] items-center flex flex-col justify-center p-5">
                <div className="items-center  flex flex-col justify-center ">
                  <img
                    src="./images/zero.png"
                    alt="zero"
                    className="md:mb-10  h-14 w-14  md:h-auto md:w-auto"
                    style={{objectFit:"contain"}}
                  />
                  <h1 className="md:text-2xl text-md font-poppinsBold text-center">
                    Zero joining fee
                  </h1>
                </div>
              </div>
              <div className="bg-[#272239] rounded-2xl md:h-[350px] md:w-72 w-40 h-[150px] items-center flex flex-col justify-center">
                <div className="items-center flex flex-col justify-center">
                  <img
                    src="./images/mobile.png"
                    alt="mobile"
                    className="md:mb-10 h-14 w-14 md:h-auto md:w-auto"
                    style={{objectFit:"contain"}}
                  />
                  <h1 className="md:text-2xl text-md font-poppinsBold text-center">
                    Seamless digital convenience
                  </h1>
                </div>
              </div>
              <div className=" bg-[#272239] rounded-2xl md:h-[350px] md:w-72 w-40 h-[150px] items-center flex flex-col justify-center p-5">
                <div className="items-center flex flex-col justify-center">
                  <img
                    src="./images/thumbs-up.png"
                    alt="thumbs-up"
                    className=" md:mb-10 h-14 w-14 md:h-auto md:w-auto"
                    style={{objectFit:"contain"}}
                  />
                  <h1 className="md:text-2xl text-md  font-poppinsBold text-center">
                    Trusted by millions
                  </h1>
                </div>
              </div>
              <div className=" bg-[#272239] rounded-2xl md:h-[350px] md:w-72 w-40 h-[150px] items-center flex flex-col justify-center p-5 md:mr-10 mr-2">
                <div className="items-center flex flex-col justify-center">
                  <img
                    src="./images/shield.png"
                    alt="shield"
                    className=" md:mb-10 h-14 w-14 md:h-auto md:w-auto"
                    style={{objectFit:"contain"}}
                  />
                  <h1 className="md:text-2xl text-md  font-poppinsBold text-center">
                    Safe, easy and transparent
                  </h1>
                </div>
              </div>
            </div>
          </Marquee>
        </div>
      </div>

      {/* Your Perfect Plan */}
      <div className="py-24">
        <h1 className="text-center font-poppinsBold md:text-3xl text-xl">
          Find Your Perfect Plan
        </h1>
        <h1 className="text-center font-poppinsSemibold md:text-3xl text-xl">
          ~ Plans starting with 1 year lock-in period ~
        </h1>

        <div className=" items-center flex flex-col mt-10 lg:flex-row justify-between lg:w-[85%] w-[90%] gap-4 m-auto font-poppinsRegular">
          <div className=" rounded-2xl bg-custom-color1  lg:p-5 p-2  items-center flex  justify-center w-full ">
            <div
              className="lg:h-[400px] lg:w-80 h-[150px] w-full items-center flex lg:flex-col gap-5 lg:gap-0
         flex-row justify-center"
            >
              <div className="h-[100%]">
                <img src="./images/plant.png" alt="plant" className=" h-full" style={{objectFit:"contain"}} />
              </div>
              <div className="items-center flex flex-col justify-center">
                <h1 className="font-poppinsSemibold">Grow at up to</h1>
                <h2 className="md:text-3xl text-xl text-[#13b9b8] font-poppinsSemibold">
                  9.5% returns*
                </h2>
                <h3>1 year lock-in period</h3>
                <h3>₹50,000 min. amount</h3>
                <p className="text-xs text-[]">*T&C Apply</p>
              </div>
            </div>
          </div>
          <div className=" rounded-2xl bg-custom-color1 lg:p-5 p-2  items-center flex justify-center w-full">
            <div className="lg:h-[400px] lg:w-80 h-[150px] items-center flex lg:flex-col gap-5 flex-row justify-center w-full">
              <div className="h-[100%] ">
                <img
                  src="./images/plant2.png"
                  alt="plant2"
                  className=" h-full"
                  style={{objectFit:"contain"}}
                />
              </div>
              <div className="items-center flex flex-col justify-center">
                <h1 className="font-poppinsSemibold">Grow at up to</h1>
                <h2 className="lg:text-3xl text-xl text-[#13b9b8] font-poppinsSemibold">
                  11.25% returns*
                </h2>
                <h3>2 year lock-in period</h3>
                <h3>₹50,000 min. amount</h3>
                <p className="text-xs text-[]">*T&C Apply</p>
              </div>
            </div>
          </div>
          <div className=" rounded-2xl bg-custom-color1 lg:p-5 p-2  items-center flex justify-center w-full">
            <div className="lg:h-[400px] lg:w-80  h-[150px] items-center flex lg:flex-col flex-row gap-5 justify-center w-full">
              <div className="h-[100%] ">
                <img
                  src="./images/plant3.png"
                  alt="plant3"
                  className=" h-full"
                  style={{objectFit:"contain"}}
                />
              </div>
              <div className="items-center flex flex-col justify-center">
                <h1 className="font-poppinsSemibold">Grow at up to</h1>
                <h2 className="lg:text-3xl text-xl text-[#13b9b8] font-poppinsSemibold">
                  12% returns*
                </h2>
                <h3> 5 year lock-in period</h3>
                <h3>₹50,000 min. amount</h3>
                <p className="text-xs  text-white">*T&C Apply</p>
              </div>
            </div>
          </div>
        </div>
        <div className="h-12 w-48 m-auto mt-10">
          <button
            onClick={openPlayStore}
            className="text-lg rounded-3xl bg-custom-color1 text-white border border-[#7745ff] h-[100%] w-[100%] hover:bg-[#7745ff] active:bg-[#7745ff]"
          >
            Invest Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default GoldPlus;
