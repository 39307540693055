import React from 'react'
import Router from './Router';
import Footer from "../components/Footer"
import ScrollToTopFeature from '../components/ScrollToTopFeature';

const Layout = () => {
  return (
    <>
      {/* <ScrollToTopFeature> */}
        <Router/>
          <Footer/>
          
      {/* </ScrollToTopFeature> */}
    </>
  )
}

export default Layout