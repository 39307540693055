import React, { useEffect, useState } from "react";
import AlljobsData from "./AllJobs.json";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

const Apply = () => {
  const navigate=useNavigate()
  const { Jobs } = AlljobsData;

  const [jobs] = useState(Jobs);

  const handleJobs=(id)=>{
   navigate(`/applyjobs/${id}`)
   window.scrollTo(0, 0);
  }

  const [isAutoplay, setIsAutoplay] = useState(true);
  const [hideArrows, setHideArrows] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Disable autoplay for medium (md) screens and above
      setIsAutoplay(window.innerWidth < 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setHideArrows(window.innerWidth >= 1024); // Hide arrows for medium and larger screens
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: isAutoplay,
    // centerMode: true,
    // centerPadding: '0',
    // centerMargin: '100px',
    autoplaySpeed: 3000,
    arrows: !hideArrows, // Show arrows unless hideArrows is true

    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          prevArrow: false, // Hide arrows for md screens
          nextArrow: false, // Hide arrows for md screens
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          prevArrow: false, // Hide arrows for md screens
          nextArrow: false, // Hide arrows for md screens
        },
      },
    ],
  };

  
  
  return (
    <div className="bg-custom-color1 text-white py-10 font-poppinsRegular">
    <div className="w-[85%] m-auto ">
      {/* top filter section  */}
      <div 
      // className=" md:items-center items-start flex flex-col md:flex-row md:justify-center gap-10 text-md py-20"
      >
      <Slider {...settings} style={{paddingLeft:"8%"}}>
        <div className="  h-10 ">
          <select
            name=""
            id=""
            className=" h-[100%] md:w-48 w-32 px-3 bg-[] text-black rounded-md hover:bg-richblack-100 cursor-pointer"
          >
            <option value="" className="text-[]">
              LOCATION TYPE
            </option>
            <option value="">All</option>
            <option value="">on-site</option>
            <option value="">hybrid</option>
            <option value="">remote</option>
          </select>
        </div>
        <div className="  h-10 ">
          <select className=" h-[100%] md:w-48 w-32 px-3  bg-[] text-black rounded-md hover:bg-richblack-100 cursor-pointer">
            <option value="">LOCATION</option>
            <option value="">All</option>
            <option value="">DELHI</option>
          </select>
        </div>
        <div className="  h-10 ">
          <select
            name=""
            id=""
            className=" h-[100%] md:w-48 w-32 px-3   bg-[] text-black rounded-md hover:bg-richblack-100 cursor-pointer"
          >
            <option value="">TEAM</option>
            <option value="">ALL</option>
            <option value="">AVENGERS...OOPS ENGINEERS</option>
            <option value="">BRAINS_PRODUCT</option>
          </select>
        </div>
        <div className=" h-10 ">
          <select
            name=""
            id=""
            className=" h-[100%] md:w-48 w-32 px-3  bg-[] text-black rounded-md hover:bg-richblack-100 cursor-pointer"
          >
            <option value="">WORK TYPE</option>
            <option value="">All</option>
            <option value="">Full Time</option>
            <option value="">Intern</option>
          </select>
        </div>
        </Slider>
      </div>

      {/* bottom jobs section */}
      <div className="">
        {jobs.map((item, i) => {
          return (
            <div
              key={item.id}
              className="my-11 items-center flex justify-between md:w-[77%] m-auto"
            >
              <div className="w-[50%]">
                <h2 className="font-poppinsRegular md:text-2xl cursor-pointer hover:text-[#7745ff]">{item.jobrole}</h2>
                <h2 className="flex gap-4">
                  {item.location_type}--{item.work_type} {item.location}
                </h2>
              </div>
              <div className="text-left  font-poppinsRegular">
             <button onClick={()=>handleJobs(item.id)} className=" text-white h-10 w-32 rounded-lg transition duration-150 ease-in-out hover:bg-[#7745ff] border border-white">
                  APPLY
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
    </div>
  );
};

export default Apply;
