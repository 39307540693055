import React, { useState } from "react";

const FaqMiddle = ({ item }) => {
  const [activeQuestion, setActiveQuestion] = useState(null);

  const handleQuestionClick = (id) => {
    setActiveQuestion(id === activeQuestion ? null : id);
  };
  return (
    <div>
      <div key={item.id} className="mb-4">
        <div
          onClick={() => handleQuestionClick(item.id)}
          className={`cursor-pointer p-[1.5rem] flex justify-between items-center font-poppinsRegular px-4 ${
            activeQuestion === item.id
              ? "bg-custom-color1 rounded-tl-lg rounded-tr-lg  "
              : "bg-custom-color1 rounded-lg"
          }`}
        >
          <div className="w-[80%]">
            <h2 className="font-poppinsSemibold">{item.question}</h2>
          </div>
          <div>
            <span className="">
              {activeQuestion === item.id ? (
                <img src="./images/up_arrow.png" alt="up_arrow" />
              ) : (
                <img src="./images/down_arrow.png" alt="down_arrow" />
              )}
            </span>
          </div>
        </div>
        {activeQuestion === item.id && (
          <div className="text-left bg-custom-color1  p-[1rem] rounded-bl-lg rounded-br-lg">
            <p>{item.answer}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FaqMiddle;
