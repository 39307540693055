import React from "react";
import Marquee from "react-fast-marquee";
import Stars from "./Stars";
import sumitGupta from "../assets/images/Sumit Gupta.jpg";
import saurabhRai from "../assets/images/Saurabh Rai.jpg";
import arpita from "../assets/images/arpitasharma.jpg";

const Rating = () => {
  const newsimg = [
    {
      rating:5,
      desc: "I saved up 6 grams gold in just 6 months on KuberPe",
      name: "Krushna",
    },
    {
      rating:5,
      desc: "I've saved & ordered a gold coin for Akshay Tritya",
      name: "Bhagvat",
    },
    {
      rating:5,
      desc: "KuberPe has made savings simpler",
      name: "Radha",
    },
    {
      rating:5,
      desc: "Saving on KuberPe has created an emergency fund ",
      name: "Vishnu",
    },
  ];
  return (
    <div id="ouruserstrust">
        <div className=" w-[100%] pt-20">
      <div className=" md:w-[85%] w-[100%] rounded-3xl bg-custom-color1 m-auto text-white my-10 py-20 font-poppinsRegular">

        <div className="md:w-[85%] w-[100] m m-auto ">
          <div className=" flex justify-center  md:flex-row  md:h-20">
            <img
              src={sumitGupta}
              className=" rounded-full h-14 md:h-14 md:w-14 w-14    bg-white   "
              alt=""
              style={{objectFit:"fill"}}
            />

            <img
              src={saurabhRai}
              className=" rounded-full h-14 md:h-14  md:w-14 w-14    bg-white   "
              alt=""
              style={{objectFit:"fill"}}
            />

            <img
              src={arpita}
              className=" rounded-full h-14 md:h-14 w-14 md:w-14   bg-white   "
              alt=""
              style={{objectFit:"fill"}}
            />
          </div>
          <div className="flex justify-center md:text-4xl text-2xl    items-center   md:flex-row">
            <h1>
              {" "}
              <b className=" text-richblack-200 "> 4 Lakh+ users 1.4L</b> trust us
            </h1>
          </div>
          <div className="flex justify-center  text-center md:text-2xl items-center text-xl flex-col  md:flex-row">
            <div className="md:w-[43%]  items-center  flex gap-5 justify-center ">
              <div>
              <p className="">
                <b className=" text-richblack-200 ">Rated</b> 4.8
              </p></div>
              <div className="">
              <Stars stars={4.8} key={4}/>
              </div>
            </div>

            <div className="md:w-[60%] lg:w-[45%] flex  justify-between  items-center flex-col md:flex-row">
              <p className="md:w-[50%] md:h-8   h-11">
                {" "}
                <b className=" text-richblack-200">1.4L reviews on</b>{" "}
              </p>

              <div className="md:w-52 ">
                <div className=" md:w-[88%]  flex justify-center  items-center">
                  <div>
                    <img
                      src="./images/imgingest.webp"
                      className=" rounded-lg   h-[30px] md:h-[30px] md:w-8  "
                      alt=""
                      style={{objectFit:"contain"}}
                    />
                  </div>
                  <p>Google play</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" m-auto mt-10">
          <Marquee pauseOnHover speed={50}>
            <div className=" flex  gap-4 mr-4 justify-center items-center ">
              
              {newsimg.map((item, index) => (
                <div className="bg-[#49445f] py-10 px-2 w-[300px] h-40 rounded-3xl">
                
                  <div className="">
                    <p className="md:text-lg text-md">{item.desc}</p>
                  </div>
              
                    <p>{item.name}</p>
                  
                  <div className="md:h-8 ">
                  <Stars stars={item.rating} key={item.rating}/>
                  </div>
                </div>
              ))}
            </div>
          </Marquee>
        </div>
      </div>
    </div>
    </div>
  
  );
};

export default Rating;
