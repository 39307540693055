import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import Rating from "../components/Rating";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "../components/Navbar";
import rishikeshImg from "../assets/images/RISHIKESH_KUMAR_RAY_copy-removebg-preview.png";
import mukeshImg from "../assets/images/MUKESH_BABU_copy-removebg-preview.png";
import alokImg from "../assets/images/alok_kumar_copy-removebg-preview.png";
import dibeshImg from "../assets/images/dibesh_kumar_copy-removebg-preview.png";
import manojImg from "../assets/images/MANOJ_KUMAR_DALAI_copy-removebg-preview.png";
import maulikImg from "../assets/images/MAULIK_CHAUDHARY_copy-removebg-preview.png";
import tejashImg from "../assets/images/TEJASH_PAREKH_copy-removebg-preview.png";
import uttamImg from "../assets/images/UTTAM_KUMAR_copy-removebg-preview.png";
import surajimg from "../assets/images/suraj.jpg"
import jyotiImg from "../assets/images/jyotipdhi-removebg-preview.png"

const company = [
  {
    icons: "./images/strongerKuberPe.png",
    description:
      " Seniority or designations don't dictate us when it comes to being hones, open or having access to information. Every voice matters. We practice it daily",
  },
  {
    icons: "./images/honesteyKuberPe.png",
    description:
      "At KuberPe we truly believe that all of us are stronger than each one of us. People are encouraged to meet each other half-way to go all the way",
  },
  {
    icons: "./images/employeeKuberPe.png",
    description:
      "Probably the most overlooked trait, consistency is all about doing the little thing, even the boring things with the same level of dedication & passion each day",
  },
  {
    icons: "./images/growthsKuberPe.png",
    description:
      "At KuberPe we believe that the sum grows only when its parts do. So individual growth is an important cornerstone of KuberPe’s growth",
  },
  {
    icons: "./images/winKuberPe.png",
    description:
      "We come to work with a simple tenet–if you want to savour the win, you have to be prepared to taste the loss as well. Own what you do & execute",
  },
  {
    icons: "./images/passion each dayKuberPe.png",
    description:
      "Whether in sport, art, or any other form- passion acts as a fuel. The people we tend to hire come with bundles of it. It’s what helps people outdo themselves",
  },
];

// const images = [
//   { icons: "./images/startupindia.png" },
//   { icons: "./images/decentro.png" },
//   { icons: "./images/registerkaro.png" },
//   { icons: "./images/lendbox-logo-2.png" },
//   { icons: "./images/Augmont.webp" },
//   { icons: "./images/PhonePe-Logo.png" },
// ];

const leaddersimg = [
  {
    leadimg: rishikeshImg,
    title: "Rishikesh Kumar Ray",
    role: "Co-Founder & Chief Product Officer",
    info: "https://www.linkedin.com/in/rcreditcardsolution/       ",
  },
  {
    leadimg: mukeshImg,
    title: "Mukesh Babu",
    role: "Co-Founder & Chief Marketing Officer",
    info: "https://www.linkedin.com/in/mukesh-babu-2a0625145/",
  },
  {
    leadimg: alokImg,
    title: "Alok Kumar",
    role: "Chief Information Officer",
    info: "https://www.linkedin.com/in/alok-kumar-455219b9/      ",
  },
  // {
  //   leadimg: dibeshImg,
  //   title: "Dibesh Parida",
  //   role: "Chief Executive Officer",
  //   info: "https://www.linkedin.com/in/dibesh-kumar-parida-0547aa2a/ ",
  // },
  // {
  //   leadimg: manojImg,
  //   title: " Manoj kumar Dalai",
  //   role: "Chief Operating Officer",
  //   info: "https://www.linkedin.com/in/manoj-k-6047721a7/      ",
  // },
  // {
  //   leadimg: maulikImg,
  //   title: "Maulik Chaudhary",
  //   role: "Chief Financial Officer",
  //   info: "https://www.linkedin.com/in/maulik-chaudhary-/",
  // },
  // {
  //   leadimg: tejashImg,
  //   title: "Tejash Parekh",
  //   role: "Chief People Officer",
  //   info: "https://www.linkedin.com/in/tejash-parekh-58458168/      ",
  // },

  {
    leadimg: surajimg,
    title: "Suraj Sheikh",
    role: "Graphics Designer",
    info: "https://www.linkedin.com/",
  },
  {
    leadimg: jyotiImg,
    title:"Jyoti Ranjan Padhi ",
    role: "Chief Technology Officer",
    info: "https://www.linkedin.com/",
  },
];

const newsimg = [
  {
    newimg: "./images/yourstory.png",
    desc: "Google & MetiY Startup Hub shortlists 25 startups from Delhi for Appsc...",
  },
  {
    newimg: "./images/business.png",
    desc: "Register Karo KuberPe is helping Indians build a habit of investing",
  },
  {
    newimg: "./images/moneyctrl.png",
    desc: "kuberPe: An app that wants to help make investing a habit ",
  },
];

const Aboutus = () => {
  const [isAutoplay, setIsAutoplay] = useState(true);
  const [hideArrows, setHideArrows] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState(0); // Initially set the first tab as selected

  const handleTabClick = (index) => {
    setSelectedTab(index); // Update selected tab when a tab is clicked
  };

  const handleNavigate = (type) => {
    if (type === "apply") {
      navigate("/apply");
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const tabs = document.querySelectorAll('.tab');
      const scrollPosition = window.scrollY;
      console.log("tabs",tabs)
      tabs.forEach((tab, index) => {
        const tabPosition = tab.offsetTop;
        const tabHeight = tab.offsetHeight;

        // Check if the tab is in the viewport
        if (scrollPosition >= tabPosition && scrollPosition < tabPosition + tabHeight) {
          setSelectedTab(index);
        }
      });
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    return () => {
      // Clean up scroll event listener
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(() => {
    // Update selected tab based on route changes
    // For example, if route changes to '/ourstory', select the second tab
    const pathname = location.pathname;
    console.log(pathname)
    if (pathname === '#ourMission') {
      setSelectedTab(0);
    } else if (pathname === '#ourstory') {
      setSelectedTab(1);
    }
    else if (pathname === '#ourinvestors') {
      setSelectedTab(2);
    }
    else if (pathname === '#ouruserstrust') {
      setSelectedTab(3);
    }
    else if (pathname === '#ourvalues') {
      setSelectedTab(4);
    }
    else if (pathname === '#meettheleaders') {
      setSelectedTab(5);
    }
    // Add more route conditions as needed

  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      // Disable autoplay for medium (md) screens and above
      setIsAutoplay(window.innerWidth < 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setHideArrows(window.innerWidth >= 1300); // Hide arrows for medium and larger screens
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: !hideArrows, // Show arrows unless hideArrows is true

    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          prevArrow: false, // Hide arrows for md screens
          nextArrow: false, // Hide arrows for md screens
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          prevArrow: false, // Hide arrows for md screens
          nextArrow: false, // Hide arrows for md screens
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          prevArrow: false, // Hide arrows for md screens
          nextArrow: false, // Hide arrows for md screens
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          prevArrow: false, // Hide arrows for md screens
          nextArrow: false, // Hide arrows for md screens
        },
      },
    ],
  };

  return (
    <div className=" bg-custom-color2 w-[100%] font-poppinsRegular">
     <Navbar backgroundColour={'bg-custom-color2'}/>
      {/* 1st section */}
      <div className="sticky  top-0 z-10 bg-custom-color2">
        <div className="w-[85%] m-auto py-5">

          <Slider {...settings}>

            {/* Content Section */}

            <div onClick={() => handleTabClick(0)} className={`tab ${selectedTab === 0 ? 'bg-[#7745ff]' : 'bg-custom-color2'} h-14 text-sm md:text-lg   text-white  rounded-xl  text-center p-[1rem] hover:bg-custom-color4  focus:bg-[#7745ff]   cursor-pointer`}>
              <a href="#ourMission">
                <button>Our mission</button>
              </a>
            </div>

            <div onClick={() => handleTabClick(1)} className={`tab ${selectedTab === 1 ? 'bg-[#7745ff]' : 'bg-custom-color2'} h-14 text-sm md:text-lg   text-white  rounded-xl  text-center p-[1rem] hover:bg-custom-color4  focus:bg-[#7745ff]   cursor-pointer`}>
              <a href="#ourstory">
                {" "}
                <button>Our story</button>
              </a>
            </div>

            <div onClick={() => handleTabClick(2)} className={`tab ${selectedTab === 2 ? 'bg-[#7745ff]' : 'bg-custom-color2'} h-14 text-sm md:text-lg   text-white  rounded-xl  text-center p-[1rem] hover:bg-custom-color4  focus:bg-[#7745ff]   cursor-pointer`}>
              <a href="#ourinvestors">
                {" "}
                <button>Our Investors</button>
              </a>
            </div>

            <div onClick={() => handleTabClick(3)} className={`tab ${selectedTab === 3 ? 'bg-[#7745ff]' : 'bg-custom-color2'} h-14 text-sm md:text-lg   text-white  rounded-xl  text-center p-[1rem] hover:bg-custom-color4  focus:bg-[#7745ff]   cursor-pointer`}>
              <a href="#ouruserstrust">
                {" "}
                <button>Users who trust us</button>
              </a>
            </div>

            <div onClick={() => handleTabClick(4)} className={`tab ${selectedTab === 4 ? 'bg-[#7745ff]' : 'bg-custom-color2'} h-14 text-sm md:text-lg   text-white  rounded-xl  text-center p-[1rem] hover:bg-custom-color4  focus:bg-[#7745ff]   cursor-pointer`}>
              <a href="#ourvalues">
                {" "}
                <button> Our values</button>
              </a>
            </div>

            <div onClick={() => handleTabClick(5)} className={`tab ${selectedTab === 5 ? 'bg-[#7745ff]' : 'bg-custom-color2'} h-14 text-sm md:text-lg   text-white  rounded-xl  text-center p-[1rem] hover:bg-custom-color4  focus:bg-[#7745ff]   cursor-pointer`}>
              <a href="#meettheleaders">
                {" "}
                <button>Meet the leaders</button>
              </a>
            </div>

          </Slider>

        </div>
      </div>

      {/* 2nd section */}
      <div className="home" id="home" >
        <div className=" md:w-[85%] w-[100%]  py-20 m-auto text-white  md:h-60   flex flex-col gap-3 items-center justify-center">
          <h1 className=" font-poppinsBold md:text-5xl h-14 text-3xl md:h-38">
            Our <b className=" text-yellow-100">mission</b>
          </h1>
          <div className="md:w-[80%] w-[80%] text-sm">
            <h2 className="  md:text-2xl text-center  text-gray-400">
              We want to reacquaint people with the power of savings through the
              concept of KuberPe Digital bank (remember those?), and ultimately
              help them grow their savings by providing access to other
              financial products all in one place.
            </h2>
          </div>
        </div>
      </div>

      {/* 3rd section */}
      <div id="ourstory">
        <div className=" md:w-[85%] w-[100%] m-auto text-white flex  flex-col  justify-center py-20">
          <div className=" md:py-20 bg-custom-color1 md:w-[85%] rounded-3xl m-auto">
            <div className=" md:w-[95%]  mt-5 w-[90%] m-auto md:text-2xl text-xl">
              <div>
                <p className=" text-pure-greys-100"> Our story</p>
              </div>
              <div className="md:text-4xl text-2xl  font-poppinsBold h-16">
                <h1>We were born in 2023</h1>
              </div>
              <div className="pb-[2rem] md:pb-0">
                <p className=" text-pure-greys-100">
                  But we didn't exactly take baby steps. Within 3 months of
                  inceptin, we reached 0.5 million users.The product and market
                  gave each other a big thumbs up and we were in business. From
                  this point on, we went out with a single-minded mission: to
                  help Indians embark on their savings journey. And we wanted to
                  throw the KuberPe out while we did that. Today we're 4Lakh +
                  users strong and adding value to millions of Indians' lives,
                  one rupee at a time. And yes, that's minus the KuberPe.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 4th section */}

      <div id="ourinvestors">
        <div className="our bg-custom-color1 w-[100%]  py-20">
          <div className=" md:w-[85%]  w-[100%]  m-auto text-white   flex flex-col gap-3 items-center justify-center">
            <h1 className=" font-poppinsBold md:text-5xl text-3xl">
              Our investors
            </h1>
            <div className=" w-[80%]">
              <h2 className="text-sm md:text-2xl text-center  text-gray-400">
                The ones we <b className=" text-yellow-100">lean</b> on
              </h2>
            </div>
          </div>


          <div className=" m-auto  w-[100%]   py-10 md:w-[85%]">
          <Marquee pauseOnHover speed={100}>
              <div className="w-[95%] flex gap-16 justify-center items-center">
                <div className=" md:h-24  h-24 rounded-lg border border-[#866A04] flex justify-center items-center ">
                  <img
                    src="./images/startupindiaKuberPe.png"
                    className="  bg-white h-[100%] w-[100%] rounded-lg  "
                    alt=""
                  />
                </div>

                <div className="  md:h-24  h-24 rounded-lg border border-[#866A04] flex justify-center items-center ">
                  <img
                    src="./images/decentroKuberPe.png"
                    className=" bg-white h-[100%] w-[100%] rounded-lg  "
                    alt=""
                  />
                </div>

                <div className="  md:h-24  h-24 rounded-lg border border-[#866A04] flex justify-center items-center ">
                  <img
                    src="./images/lendboxKuberPe.png"
                    className="  bg-white h-[100%] w-[100%] rounded-lg  "
                    alt=""
                  />
                </div>

                <div className="  md:h-24  h-24 rounded-lg border border-[#866A04] flex justify-center items-center ">
                  <img
                    src="./images/phonepeKuberPe.png"
                    className=" bg-white h-[100%] w-[100%] rounded-lg  "
                    alt=""
                  />
                </div>

                <div className="  md:h-24  h-24  rounded-lg border border-[#866A04] flex justify-center items-center ">
                  <img
                    src="./images/augmontKuberPe.png"
                    className="  bg-white h-[100%] w-[100%] rounded-lg  "
                    alt=""
                  />
                </div>
              </div>
            </Marquee>
          </div>
        </div>
      </div>

      {/* Rating */}
      <div>
        <Rating />
      </div>
      {/* 5th section */}

      <div id="ourvalues">
        <div className="w-[100%]  bg-custom-color2  pb-20">
          <div className=" md:w-[85%] w:[100%]  m-auto text-white my-10 flex flex-col gap-2 items-center justify-center">
            <h1 className=" font-poppinsBold md:text-5xl text-center text-2xl">
              The <b className=" text-yellow-100">values</b> that drive us
            </h1>
          </div>
          <div className="md:w-[85%]   w-[100%]  m-auto  grid  justify-items-center md:grid-cols-3 grid-cols-1 gap-16">
            {company.map((item, index) => (
              <div
                key={index}
                className="flex flex-col   rounded-lg text-richblack-100 p-[2rem] items-center  md:h-72 h-72 md:w-72 w-[100%] bg-custom-color1 "
              >
                <div className="icons ">
                  <img
                    src={item.icons}
                    className="h-20 md:h-20 w-20 rounded-full      border-pure-greys-300"
                    alt=""
                  />
                </div>
                <div>
                  <p>{item.description}</p>{" "}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* 6th section */}

      <div className="meettheleaders  h-max-content flex items-center justify-center  bg-custom-color1">
        <div className="w-[85%]  py-10">
          <div className="w-[100%] m-auto  text-white  flex-col">
            <h1 className=" font-poppinsBold text-2xl text-center  p-[1rem] md:text-5xl">
              Meet the <b className=" text-yellow-100">leaders</b> of our ship
            </h1>
          </div>
          <div className="md:w-[90%]  m-auto  w-[100%] grid  justify-items-center md:grid-cols-2 lg:grid-cols-3 grid-cols-1 flex-wrap  gap-10 mt-10">
            {leaddersimg.map((item, index) => (
              <div key={index} className="flex flex-col mb-[1rem] h-[400px] w-[250px] rounded-lg  items-center  text-white">
                  <img
                    src={item.leadimg}
                    className="h-[80] md:h-[75%] lg:h-[65%] w-[100%] rounded-lg  bg-white"
                    alt={item.title}
                    style={{objectFit:"fill"}}
                  />
                <div className="w-[100%]  mt-[1rem] md:h-32 ">
                  <p className="md:text-lg text-sm font-poppinsBold ">{item.title}</p>
                  <p className="text-md">{item.role}</p>

                  <p className="md:text-lg text-sm text-yellow-100 font-poppinsBold ">
                    <a href={item.info ? item.info : null}>Linkedin</a>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/*7th section */}

      <div className="   bg-custom-color2 py-20 ">
        <div className=" md:w-[85%]  w-[100%] m-auto text-white flex flex-col gap-3 items-center justify-center">
          <h1 className=" font-poppinsBold md:text-5xl text-3xl ">
            KuberPe in <b className=" text-yellow-100">News</b>
          </h1>
        </div>

        <div className=" m-auto  w-[100%]  my-10   md:w-[100%]">
          <Marquee pauseOnHover speed={100}>
            <div className="w-[95%]  md:h-[180px] flex  gap-16 justify-center items-center">
              {newsimg.map((item, index) => (
                <div className=" bg-white md:w-96 w-72 h-36 rounded-2xl p-[1rem] ">
                  <img
                    src={item.newimg}
                    className=" rounded-lg h-16 md:h-12  md:w-52 w-36 bg-white"
                    alt=""
                  />
                  <div className="w-[100%]  leading-10   md:h-20 ">
                    <p className="md:text-lg text-sm">{item.desc}</p>
                  </div>
                </div>
              ))}
            </div>
          </Marquee>
        </div>
      </div>

      {/* 8th section */}

      {/* ** */}

      <div className=" py-20">
        <div className="md:w-[70%]  m-auto text-white rounded-3xl bg-custom-color1 flex flex-col items-center justify-center  ">
          <div className="items-center border border-b-white relative p-4 flex justify-center bottom-16 bg-custom-color1 rounded-full">
            <img
              src="./images/search.svg"
              alt="automated syastem"
              className=""
            />
          </div>
          <div className=" items-center md:w-[80%] flex justify-center flex-col">
            <h1 className="font-bold md:text-3xl text-3xl md:h-20 text-center h-24 font-poppinsBold">
              That's our story,{" "}
              <b className=" text-yellow-100 font-poppinsBold">What's yours?</b>
            </h1>
            <h2 className="md:text-2xl text-xl text-center font-poppinsBold">
              We're looking for people who are passionate about FinTech and wish
              to learn more about it{" "}
            </h2>
            <div className="md:h-36 w-60 text-center md:w-60 py-5">
              {" "}
              <button
                onClick={() => handleNavigate("apply")}
                className=" md:w-[90%] text-center w-[80%] items-center h-14 mt-8  rounded-3xl border font-poppinsSemibold transition duration-150 ease-in-out hover:text-[#7745ff]"
              >
                Check Openings
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
