import React from 'react';
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";

const Stars = ({ stars }) => {

    let starpattern = Array.from({ length: 5 }, (el, ind) => {
        // console.log(ind)
        let half = ind + 0.5;
        // console.log(stars)
        return (
          <span key={ind}>
            {
            stars >= ind + 1 ? (
              <FaStar className="text-yellow-100 " />
            ) : stars >= half ? (
              <FaStarHalfAlt className="text-yellow-100" />
            ) 
            : (
              <AiOutlineStar className="text-yellow-100" />
            )
            }
          </span>
        );
      });
  return (
    <div className="flex items-center gap-[20px]">
    <div className="flex"> {starpattern}</div>
    {/* <h2>({stars})</h2> */}
  </div>
  )
}

export default Stars