import React from "react";

const Refund = () => {
  return (
    <div className="bg-richblack-900  text-white">
      <div className="w-[85%] m-auto flex flex-col gap-9 py-20 font-poppinsRegular">
        <h1 className="md:text-6xl text-2xl font-poppinsBold md:h-32 items-center flex ">
          Refund Policy | KuberPe
        </h1>

        {/* 1.RETURN AND CANCELLATION POLICY */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
            1. RETURN AND CANCELLATION POLICY
            <br /> <br />
          </h2>

          <ul className="text-xl text-[#717171] font-poppinsRegular border border-[#4e4e4e] rounded-lg p-[2rem] list-disc">
            <li>
              On use of any coupon or discounts, as made available by us on the
              Platform or any other website or platform of any authorised third
              party, for purchase of Gold, the same shall be verified and
              adjusted, as may be necessary, within 7 (seven) days of use of
              such coupon or discount.
              <br /> <br />
            </li>
            <li>
              Upon confirmation of the payment, you shall be permitted to
              withdraw the monies after 24 (twenty-four) hours of investment,
              and there is no minimum lock-in period.
              <br /> <br />
            </li>
            <li>
              Once the payment has been confirmed, the same shall be binding on
              You and cannot be cancelled.
              <br /> <br />
            </li>
            <li>
              You can opt out of auto investing on the Platform by undertaking
              the following actions: 
              <br /> <br />
            </li>
            <li>
              Tap on 'Locker' in the bottom right corner on the mobile
              application.
              <br /> <br />
            </li>
            <li>
              Go to 'Settings' by tapping on the icon on the top right corner
              on the mobile application.
              <br /> <br />
            </li>
            <li>
              Scroll down and find ‘AutoPay’ under transactions and withdrawals
              on the mobile application.
              <br /> <br />
            </li>
            <li>
              Disable AutoPay by tapping on the toggle on the mobile
              application.
              <br /> <br />
            </li>
            <li>
              You can also pause their roundup detection and savings for a
              defined period of time. You can find the option of pausing Your
              saving for 10 (ten) days to 1 (one) month in the settings of the
              mobile application. 
              <br /> <br />
            </li>
          </ul>
        </div>

        {/* 2.REFUND POLICY */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
            2. REFUND POLICY
            <br /> <br />
          </h2>

          <ul className="text-xl text-[#717171] font-poppinsRegular border border-[#4e4e4e] rounded-lg p-[2rem] list-disc">
            <li>
              Please note, once an order for purchase or sale has been
              confirmed by the customer, refunds or cancellation is not
              possible. All orders are final upon customer confirmation
              <br /> <br />
            </li>
            <li>
              Deliveries are made only to the registered address of the
              customer. Please keep proof of identity ready at the time of
              delivery as it may be required for verification by the courier
              partner. If no one is available at the registered address to take
              delivery, delivery will be attempted at least once more after
              which the package will be returned to the vault and the customer's
              balance will be credited back with the corresponding grams of
              gold. Shipping charges will be payable again as and when the
              customer asks for delivery.
              <br /> <br />
            </li>
            <li>
              Customers are required to verify that the package they are
              accepting has not been tampered with and is of acceptable quality.
              Once delivery has been taken by the customer, returns and refunds
              are not possible. All of our products are 100% certified and there
              should never be any problems with quality. In the unlikely event
              of any issues, feel free to contact us, we will investigate the
              matter to resolve any concerns or issues.
              <br /> <br />
            </li>
            <li>
              You can email us at <span className="text-[#2E8BFF]">manoj@kuberpe.com</span>. We assure you that your
              request will be addressed on priority.
              <br /> <br />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Refund;
