import React, { useState } from "react";

const Form = () => {
  const openPlayStore = () => {
    // URL for your app on the Play Store
    const playStoreUrl = 'http://app.kuberpe.com/refer/SRK892';
    window.open(playStoreUrl, '_blank');
};

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phone: "",
    currentLocation: "",
    currentCompany: "",
    linkedInUrl: "",
    githubUrl: "",
    techQuestions: {
      q1: "",
      q2: "",
      q3: "",
      q4: "",
      q5: "",
    },
    currentCompanyLocation: "",
    willingToRelocate: false,
    referenceName: "",
    referenceDesignation: "",
    KuberPeApplications: "",
    referenceContact: "",
    ctcDetails: {
      currentCTC: "",
      expectedCTC: "",
      noticePeriod: "",
    },
    additionalInformation: "",
    resume: null,
  });

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else if (type === "file") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0], // Store only the first file if multiple files are selected
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {

    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
    
    // navigate('http://app.kuberpe.com/refer/SRK892')
  };

  return (
    <div className="bg-custom-color1 text-white py-10">
    <div className="md:w-[60%] w-[90%] m-auto font-poppinsRegular">
      <div className="pt-20 font-poppinsRegular">
        <div className="items-center flex flex-col md:flex-row  justify-between ">
          <div>
            <h2 className="font-poppinsRegular md:text-2xl text-3xl cursor-pointer hover:text-blue-400 text-center">
              Android Developer(Mid/Senior)
            </h2>
            <h2 className="text-center md:text-left">
              FULL TIME--ON-SITE DELHI
            </h2>
          </div>
        </div>
      </div>

      <div className="">
        <form onSubmit={handleSubmit} className="">
          <h1 className="text-2xl my-10">SUBMIT YOUR APPLICATION</h1>


          <div className="md:items-center flex flex-col md:flex-row justify-between gap-5 my-5">
            <div>
              <label className="md:text-xl "> Resume/CV:</label>
            </div>

            <div className="h-10 w-[70%] ">
            <input type="file" name="resume" placeholder="ATTACH RESUME/CV" onChange={handleChange} />
            </div>
          </div>

          <div className="md:items-center  flex flex-col md:flex-row justify-between gap-5 my-5">
            <div>
              <label className="md:text-xl "> Full name:</label>
            </div>

            <div className="h-10 md:w-[70%]">
              <input
                type="text"
                name="fullname"
                className="w-full bg-[#2e2942] h-full rounded-sm"
                value={formData.fullname}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="md:items-center flex flex-col md:flex-row justify-between gap-5 my-5">
            <div>
              <label className="md:text-xl ">Email : </label>
            </div>
            <div className="h-10 md:w-[70%]">
              <input
                type="text"
                name="email"
                className="w-full h-full bg-[#2e2942] rounded-sm"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="md:items-center flex flex-col md:flex-row gap-5 justify-between my-5">
            <div>
              <label className="md:text-xl ">Phone :</label>
            </div>
            <div className="h-10 md:w-[70%]">
              <input
                type="number"
                name="phone"
                className="w-full h-full bg-[#2e2942] rounded-sm"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="md:items-center flex flex-col md:flex-row gap-5 justify-between my-5">
            <div>
              <label className="md:text-xl ">Current Location :</label>
            </div>
            <div className="h-10 md:w-[70%]">
              <input
                type="text"
                name="currentLocation"
                className="w-full h-full bg-[#2e2942] rounded-sm"
                value={formData.currentLocation}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="md:items-center flex flex-col md:flex-row gap-5 justify-between my-5">
            <div>
              <label className="md:text-xl ">Current Company : </label>
            </div>
            <div className="h-10 md:w-[70%]">
              <input
                type="text"
                name="currentCompany"
                className="w-full h-full bg-[#2e2942] rounded-sm"
                value={formData.currentCompany}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* links */}

          <h1 className="text-2xl mt-20">LINKS</h1>

          <div className="md:items-center flex flex-col md:flex-row gap-5 justify-between my-5">
            <div>
              <label className="md:text-xl ">LinkedIn URL :</label>
            </div>
            <div className="h-10 md:w-[70%]">
              <input
                type="text"
                name="linkedInUrl"
                className="w-full h-full bg-[#2e2942] rounded-sm"
                value={formData.linkedInUrl}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="md:items-center flex flex-col md:flex-row gap-5 justify-between my-5">
            <div>
              <label className="md:text-xl "> Github URL :</label>
            </div>
            <div className="h-10 md:w-[70%]">
              <input
                type="text"
                name="githubUrl"
                className="w-full h-full bg-[#2e2942] rounded-sm"
                value={formData.githubUrl}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Tech Questions: */}
          <h2 className="text-2xl mt-20">Tech Questions: </h2>
          <ol className="">
            <li className=" md:text-xl">
              <div className=" my-5">
                <label>
                  1.Why would you like to work with KuberPe? How will it help you
                  accomplish your career goals?
                </label>
                <div className="h-32 my-5">
                  <textarea
                    name="techQuestions.q1"
                    className="w-full h-full bg-[#2e2942] rounded-sm"
                    value={formData.techQuestions.q1}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </li>

            <li className=" md:text-xl">
              <div className=" my-5">
                <label>
                  2.Why do you want to join a fintech startup which promotes
                  financial independence?
                </label>
                <div className="h-32 my-5">
                  <textarea
                    name="techQuestions.q2"
                    className="w-full h-full bg-[#2e2942] rounded-sm"
                    value={formData.techQuestions.q2}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </li>

            <li className=" md:text-xl">
              <div className=" my-5">
                <label>
                  3.Mention an instance professionally/personally where you’ve
                  gone the extra mile and what did you achieve?
                </label>
                <div className="h-32 my-5">
                  <textarea
                    name="techQuestions.q3"
                    className="w-full h-full bg-[#2e2942] rounded-sm"
                    value={formData.techQuestions.q3}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </li>

            <li className=" md:text-xl">
              <div className=" my-5">
                <label>
                  4.What is the Biggest achievement /upskilling in tech that you
                  have recently done?
                </label>
                <div className="h-32 my-5">
                  <textarea
                    name="techQuestions.q4"
                    className="w-full h-full bg-[#2e2942] rounded-sm"
                    value={formData.techQuestions.q4}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </li>

            <li className=" md:text-xl">
              <div className=" my-5 ">
                <label>
                  5.What is that next breakthrough you are planning to make in
                  Tech?
                </label>
                <div className="h-32 my-5">
                  <textarea
                    name="techQuestions.q5"
                    className="w-full h-full bg-[#2e2942] rounded-sm"
                    value={formData.techQuestions.q5}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </li>
          </ol>

          {/* Add more input fields */}
          <div className="">
            <label className="md:text-xl ">Mention your Current Company</label>
            <div className="h-32 w-[98%] my-5">
              <textarea
                name="techQuestions.currentCompanyLocation"
                className="w-full h-full bg-[#2e2942] rounded-sm"
                value={formData.techQuestions.currentCompanyLocation}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className=" my-5">
            <label className="md:text-xl ">What is your current location ?</label>
            <div className="h-10 w-[98%] my-5">
              <input
                name="techQuestions.currentCompany"
                className="w-full h-full bg-[#2e2942] rounded-sm p-3"
                placeholder="Type your response"
                value={formData.techQuestions.currentCompany}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className=" my-5">
            <label className="md:text-xl ">
              Are you open to relocate to Delhi for a Work from office model
              ?
            </label>
            <div className="h-32 flex flex-col">
              <div className="p-5">
                <input
                  type="checkbox"
                  name="willingToRelocate"
                  value={formData.willingToRelocate}
                  onChange={handleChange}
                />{" "}
                <label htmlFor="">Yes</label>
              </div>
              <div className="p-5">
                <input
                  type="checkbox"
                  name="willingToRelocate"
                  value={formData.willingToRelocate}
                  onChange={handleChange}
                />{" "}
                <label htmlFor="">No</label>
              </div>
            </div>
          </div>

          <div className=" my-5">
            <label className="md:text-xl ">
              How many times have you applied in KuberPe ?
            </label>
            <div className="h-10 w-[98%] my-5">
              <input
                name="KuberPeApplications"
                className="w-full h-full bg-[#2e2942] rounded-sm p-3"
                placeholder="Type your response"
                value={formData.KuberPeApplications}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className=" my-5">
            <label className="md:text-xl ">
              Mention a reference name along with designation from your current
              organization (Manager/lead)
            </label>
            <div className="h-10 w-[98%] my-5">
              <input
                name="referenceName"
                className="w-full h-full bg-[#2e2942] rounded-sm p-3"
                placeholder="Type your response"
                value={formData.referenceName}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className=" my-5">
            <label className="md:text-xl ">
              Contact details of Reference (email/phone no.)
            </label>
            <div className="h-32 w-[98%] my-5">
              <textarea
                name="referenceContact"
                className="w-full h-full bg-[#2e2942] rounded-sm "
                value={formData.referenceContact}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className=" my-5">
            <label className="md:text-xl ">
              What is your Current CTC Breakup ?
            </label>
            <div className="h-10 w-[98%] my-5">
              <input
                name="ctcDetails"
                className="w-full h-full bg-[#2e2942] rounded-sm p-3"
                placeholder="Type your response"
                value={formData.ctcDetails.currentCTC}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* ctc details */}
          <h2 className="text-2xl mt-20">CTC DETAILS</h2>

          <div className=" my-5">
            <label className="md:text-xl ">Current CTC - Breakup</label>
            <div className="h-10 w-[98%] my-5">
              <input
                name="ctcDetails.currentCTC"
                className="w-full h-full bg-[#2e2942] rounded-sm p-3"
                placeholder="Type your response"
                value={formData.ctcDetails.currentCTC}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className=" my-5">
            <label className="md:text-xl ">Expected CTC</label>
            <div className="h-10 w-[98%] my-5">
              <input
                name="ctcDetails.expectedCTC"
                className="w-full h-full bg-[#2e2942] rounded-sm p-3"
                placeholder="Type your response"
                value={formData.ctcDetails.expectedCTC}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className=" my-5">
            <label className="md:text-xl ">Notice Period</label>
            <div className="h-32 w-[98%] my-5">
              <input
                name="ctcDetails.noticePeriod"
                className="w-full h-full bg-[#2e2942] rounded-sm p-3"
                value={formData.ctcDetails.noticePeriod}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className=" my-5">
            <label className="md:text-xl ">ADDITIONAL INFORMATION</label>
            <div className="h-32 w-[98%] my-5">
              <textarea
                name="additionalInformation"
                className="w-full h-full bg-[#2e2942] rounded-sm p-3"
                placeholder="Add a cover letter or anything you want to share"
                value={formData.additionalInformation}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="items-center flex justify-center my-10">
            <button
              onClick={openPlayStore}
              type="submit"
              className=" text-white h-10 w-60 border border-white  rounded-lg transition duration-150 ease-in-out hover:text-[#7745ff] bg-custom-color1"
            >
              SUBMIT APPLICATION
            </button>
          </div>
        </form>
      </div>
    </div>
    </div>
  );
};

export default Form;
