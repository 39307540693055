import React from "react";
import Navbar from "../components/Navbar";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa6";

const Disclaimer = () => {
  return (
    <div className="bg-richblack-900  text-white">
      <Navbar backgroundColour={"bg-richblack-900"} />

      {/* disclaimer content section */}
      <div className="w-[85%] m-auto flex gap-9 flex-col py-20 font-poppinsRegular">
        <h1 className="md:text-4xl text-2xl font-poppinsBold text-center">
          DISCLAIMER
        </h1>
        <h2 className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[1.5rem] font-poppinsRegular">
          Dazala Tech Private Limited and their respective publishers, authors,
          agents and employees have done their best to ensure the accuracy of
          all the information on this website contributed by them; however, they
          accept no responsibility for any loss, injury, or damages sustained by
          anyone as a result of information or advice contained on the site. The
          use of information on or derived from this site is at the user's own
          risk.
          <br /> <br />
          The documents and related graphics published on this website could
          include technical inaccuracies or typographical errors. Changes are
          periodically added to the information herein.Dazala Tech Private
          Limited may make improvements and/or changes in the product(s)
          described herein at any time. The linked sites are not under the
          control of Dazala Tech Private Limited and their respective employees
          are not responsible for the contents of any linked site or any link
          contained in a linked site. Dazala Tech Private Limited is providing
          these external links to you only as a convenience, and the inclusion
          of any link does not imply endorsement by Dazala Tech Private Limited
          of the site. All views expressed by individuals on this site are their
          personal opinions and are not necessarily those of or endorsed by
          Dazala Tech Private Limited.
          <br />
        </h2>

        {/* follow us section */}
        <div className="mt-20">
          <h1 className="md:text-4xl text-2xl font-poppinsBold text-center">
            FOLLOW US ON
          </h1>
          <div className="flex justify-around m-auto items-center md:w-[40%] w-full pt-12">
            <div className="">
              <a href="https://www.instagram.com/kuberpe_now/" target="blank">
                {" "}
                <FaInstagram style={{ height: "40px", width: "40px" }} />
              </a>
            </div>
            <div>
              <a href="https://www.facebook.com/kuberpe/" target="blank">
                {" "}
                <FaFacebook style={{ height: "40px", width: "40px" }} />
              </a>
            </div>
            <div>
              <a
                href="https://www.linkedin.com/company/99000719/admin/feed/posts/"
                target="blank"
              >
                {" "}
                <FaLinkedin style={{ height: "40px", width: "40px" }} />
              </a>
            </div>
            <div>
              <a href="https://twitter.com/gold_kuberpe" target="blank">
                {" "}
                <FaTwitter style={{ height: "40px", width: "40px" }} />
              </a>
            </div>

            <div>
              <a href="https://www.youtube.com/@kuberpe" target="blank">
                {" "}
                <FaYoutube style={{ height: "40px", width: "40px" }} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
