import React from "react";
import { CiHeart } from "react-icons/ci";
import goldProStatics from "../assets/images/peer to peer new.png";
import CuratedOpportunities from "../assets/images/Curated Opportunities.png";
import  TransparencyFocused from "../assets/images/Transparency Focused.png";
import DataTech from "../assets/images/data and tech.png";
import Navbar from "../components/Navbar";
import kycGoldproImg from "../assets/images/KYCldpi.png"
const Goldpro = () => {
  const openPlayStore = () => {
    // URL for your app on the Play Store
    const playStoreUrl = "http://app.kuberpe.com/refer/SRK892";
    window.open(playStoreUrl, "_blank");
  };

  return (
    <div className="bg-custom-color2 text-white font-poppinsRegular">
      <Navbar backgroundColour={'bg-custom-color1'}/>

      {/* top header box */}

      <div className="h-max-content bg-custom-color1 flex flex-col  items-center justify-center w-[100%] ">
        <div className="md:w-[85%] w-[100%] mt-10 pb-10 md:h-[92.7%] h-[70%] flex items-center justify-center">
          <div className="h-[100%] flex flex-col items-center jsutify-center md:w-[60%] w-[100%]">
            <p className="md:text-5xl text-3xl text-white font-poppinsRegular text-center font-bold">
              GoldPro
            </p>
            <p className="md:text-3xl text-xl mt-[2rem] text-white tracking-wider font-poppinsRegular text-center">
              Grow your wealth with the <br /> power of compounding
            </p>
            <p className="mt-[2rem] text-white font-bold md:text-2xl text-xl font-poppinsRegular tracking-wider text-center">
              FMPP-LUMP SUM <br />
              Earn upto <span className="text-[yellow]">14%</span> p.a.
            </p>
            <div className="md:w-[65%] w-[100%] mt-[2rem] items-center flex  justify-center gap-[1rem]">
              <div className="flex gap-2 justify-center items-center">
                <div className="h-[10px] w-[10px] rounded-[50%] bg-[yellow]"></div>
                <p className="font-semibold tracking-wide md:text-md text-xs text-white font-poppinsRegular">
                  Monthly
                </p>
              </div>
              <div className="flex gap-2 justify-center items-center">
                <div className="h-[10px] w-[10px] rounded-[50%] bg-[yellow]"></div>
                <p className="font-semibold tracking-wide md:text-md text-xs  text-white font-poppinsRegular">
                  Passive
                </p>
              </div>
              <div className="flex gap-2 justify-center items-center">
                <div className="h-[10px] w-[10px] rounded-[50%] bg-[yellow]"></div>
                <p className="font-semibold tracking-wide md:text-md text-xs  text-white font-poppinsRegular">
                  Predictable Performance
                </p>
              </div>
            </div>
            <div className="md:w-[65%] w-[100%] mt-[2rem] flex items-center justify-center gap-[1rem]">
              <button
                onClick={openPlayStore}
                class="bg-blue-900 text-white px-16 py-2 rounded-lg text-lg font-semibold shadow-lg hover:bg-blue-800"
              >
                Start Investing
              </button>
            </div>
            <p className="mt-[2rem] text-white font-bold text-2xl font-poppinsRegular tracking-wider text-center">
              RBI Certified NBFC-P2P
            </p>

            <div className="md:w-[50%] w-full flex  justify-center h-100% mt-10">
              <img
                src="./images/Earn uptokuberji.png"
                alt="twsimage"
                className="rounded-lg h-56 w-72"
                style={{objectFit:"contain"}}
              />
            </div>
          </div>
        </div>
      </div>

      {/* 2nd section what is goldpro */}

      <div className="h-max-content  bg-[#2e2942] text-white flex flex-col  items-center justify-center w-[100%]">
        <div className="md:w-[85%] w-[100%] my-20 md:h-[92.7%] h-[70%]">
          <div className=" md:w-[85%]  w-[100%]   m-auto   text-center  flex flex-col gap-3 items-center justify-center">
            <h1 className=" font-poppinsBold md:text-5xl text-3xl">
              WHAT IS GoldPro?
            </h1>
            <div className=" w-[80%]">
              <h2 className="text-sm md:text-2xl text-center  text-gray-400">
                Platform for Digital P2P investment opportunities
              </h2>
            </div>
          </div>
          <div className="border-b border-white my-10"></div>

          <div className="w-[85%] m-auto h-[100%] items-center flex justify-between  flex-col  md:flex-row">
            <div className="md:w-[50%] text-left   ">
              <p className="text-black-700 text-3xl font-poppinsSemibold md:text-4xl  leading-12  md:h-16">
                Get a GoldPro on:
              </p>{" "}
              <br />
              <p className="md:text-2xl text-xl">Diversification</p>
              <br />
              <p className="md:text-2xl text-xl">Great Returns</p>
              <br />
              <p className="md:text-2xl text-xl">Non Market Linked Assets</p>
              <br />
            </div>

            {/* Second Column (Image Section) */}
            <div className="md:w-[50%] w-full flex  justify-center h-100% ">
              <img
                src={goldProStatics}
                alt="twsimage"
                className=""
                style={{objectFit:"contain"}}
              />
            </div>
          </div>
        </div>
      </div>

      {/* 3rd section our focus */}

      <div className="h-max-content  bg-custom-color2  flex flex-col  items-center justify-center w-[100%]">
        <div className="md:w-[85%] w-[90%] my-20 md:h-[92.7%] h-[70%]">
          <div className=" md:w-[85%]  w-[100%]   m-auto text-white text-center flex flex-col gap-3 items-center justify-center">
            <h1 className=" font-poppinsBold md:text-5xl text-3xl pb-10">
              OUR FOCUS
            </h1>
            <div className=" w-[80%] pb-10">
              <h2 className="text-sm md:text-2xl text-center  text-gray-400">
                We are committed to making financial products more inclusive
              </h2>
            </div>
          </div>

          <div className=" text-black m-auto  flex justify-center gap-10  flex-col md:flex-row  pb-10 ">
            {/* First Div */}
            <div className="rounded-lg  bg-custom-color1 text-white items-center flex flex-col h-[350px]   md:w-[28%]  text-center">
              <div className="w-28 h-[40%] items-center flex  justify-center ">
                <img
                  src={CuratedOpportunities}
                  className="rounded-lg "
                  alt="serchfileic"
                  style={{objectFit:"contain"}}
                />
              </div>
              <div className="h-[60%]">
                {" "}
                <p className="font-bold  p-[0.5rem] ">Curated Opportunities</p>
                <p className="p-[1rem]  w-[100%]">
                  Unique investment opportunities qualified through reputed
                  partners and rigorous due diligence
                </p>
              </div>
            </div>
            
            {/* Second Div */}
            <div className="rounded-lg bg-custom-color1 text-white items-center flex flex-col h-[350px] md:w-[28%] text-center">
              <div className="w-28 h-[40%] items-center flex justify-center">
                <img
                  src={TransparencyFocused}
                  className="rounded-lg"
                  alt="serchfileic"
                  style={{objectFit:"contain"}}
                />
              </div>
              <div className="h-[60%]">
                <p className="font-bold p-[0.8rem]">Transparency Focused</p>
                <p className="p-[1rem]  w-[100%] ">
                  In -depth information for each opportunity's investment
                  highlights and risks, commercial terms, returns, and partner
                  profile
                </p>
              </div>
            </div>

            {/* Third Div */}
            <div className="rounded-lg   bg-custom-color1 text-white   items-center flex flex-col h-[350px] md:w-[28%] text-center">
              <div className="w-28  h-[40%] items-center flex justify-center">
                <img
                  src={DataTech}
                  className="rounded-lg  h-[80%] w-[100%]"
                  alt="serchfileic"
                  style={{objectFit:"contain"}}
                />
              </div>

              <div className="h-[60%]">
                <p className="font-bold  p-[0.8rem]">
                  Data & Technology Driven
                </p>
                <p className="p-[1rem] w-[100%]  ">
                  Data-driven decision making with relentless dedication towards
                  utilization of latest technology to provide a seamless
                  investor experience
                </p>
              </div>
            </div>
          </div>

          <div>
            <div className=" md:w-[85%]  w-[100%]   m-auto text-white text-center  md:h-36 h-48 flex flex-col gap-3 items-center justify-center">
              <h1 className=" font-poppinsBold md:text-3xl text-xl">
                Find some great opportunities on GoldPro
              </h1>
              <div>
                <button
                  onClick={openPlayStore}
                  className="mt-[1rem] md:w-60   w-60 h-16 font-bold  bg-custom-color4  rounded-lg text-white md:text-2xl text-center   text-2xl  tracking-tight"
                >
                  View Opportunities
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 4th section */}
      <div className="h-max-content  bg-custom-color1  flex flex-col  items-center justify-center w-[100%] font-poppinsRegular">
        <div className="md:w-[85%] w-[100%] py-10 md:h-[92.7%] h-[70%]">
          <div className=" md:w-[85%]  w-[100%]  m-auto text-white text-center   md:h-28 h-36 flex flex-col gap-3 items-center justify-center">
            <h1 className="  md:text-3xl text-3xl font-poppinsRegular">
              HOW TO INVEST?
            </h1>
            <div className=" w-[80%]">
              <h2 className="text-sm md:text-4xl  font-poppinsBold text-center  text-gray-400">
                It's really simple with GoldPro
              </h2>
            </div>
          </div>

          <div className="w-[85%] h-[100%]  m-auto items-center justify-between flex flex-col-reverse  md:flex-row ">
            {/* images */}
            <div className="md:w-[50%] w-full flex  h-100% ">
              <img
                src={kycGoldproImg}
                alt="GoldPro"
                className=" h-[500px] w-full md:mt-2 md:w-[63%] md:h-[610px] "
                style={{objectFit:"contain"}}
              />
            </div>

            {/* paragraph  */}
            <div className="md:w-[50%]  md:text-3xl text-white ">
              <h2 className="text-xl">
                {" "}
                Explore Digital P2P investment opportunities process
              </h2>
              <br />

              <h2 className="text-xl">Complete Kyc and investment process</h2>
              <br />

              <h2 className="text-xl">
                Receive returns as per pre-determined schedule
              </h2>
              <br />
              <h2 className="text-xl">
                For fixed income products, receive monthly/ quarterly returns in
                your bank account
              </h2>
              <br />
            </div>
          </div>
        </div>
      </div>

      {/* 5th section */}

      <div className="h-max-content  bg-custom-color2  flex flex-col  items-center justify-center w-[100%] font-poppinsRegular">
        <div className="md:w-[85%] w-[100%] py-10 md:h-[92.7%] h-[70%]">
          <div className=" md:w-[85%]  w-[100%]  m-auto text-white text-center  md:h-60 h-48 flex flex-col gap-3 items-center justify-center">
            <div>
              <CiHeart className=" text-9xl " />
            </div>
            <h1 className="  md:text-2xl text-xl">TESTIMONIALS</h1>
            <div className=" w-[80%]">
              <h2 className=" md:text-4xl  font-poppinsBold text-center text-2xl  text-gray-400">
                Wall of <b className=" text-yellow-100">Love</b> @ KuberPe
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Goldpro;
