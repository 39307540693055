import React from "react";

function PrivacyPolicy() {
  return (
    <div className="bg-richblack-900  text-white">
      <div className="w-[85%] m-auto flex gap-9 flex-col py-20 font-poppinsRegular">
        <h1 className="md:text-6xl text-2xl font-poppinsBold md:h-32 items-center flex ">
          Privacy Policy | KuberPe
        </h1>
        <h2 className="leading-9 text-xl text-[#717171] font-poppinsRegular">Hello!</h2>
        <h2 className="text-xl text-[#717171] font-poppinsRegular">
          We at Dazala Tech Private Limited (we, us, our, or Company) take your
          privacy seriously. We are committed to safeguard your interest and
          want to keep you updated, at all times, about how we collect and use
          your data.
        </h2>

        {/* question and answers section */}
        <div className="text-xl leading-8 my-10 ">
          <h2 className="font-poppinsMedium">What is this document?</h2>
          <h2 className="text-[#717171] font-poppinsRegular">
            This document is our privacy policy. It sets out how we collect,
            process, store, and share your data when you access or use the
            KuberPe Platform.
            <br /> <br />
          </h2>

          <h2 className="font-poppinsMedium">Why is it important?</h2>
          <h2 className="text-[#717171] font-poppinsRegular">
            This Privacy Policy gives you visibility over your data. It explains
            what we can do with it and how. We will not use or share any of your
            information except as described here.
            <br /> <br />
          </h2>

          <h2 className="font-poppinsMedium">Is this part of the Terms?</h2>
          <h2 className="text-[#717171] font-poppinsRegular">
            Yes. This Privacy Policy is a part of the Terms you have agreed with
            us <span className="text-[#2E8BFF]">here</span>. Words used in this
            policy will have the same meaning given to them.
            <br /> <br />
          </h2>

          <h2 className="font-poppinsMedium">Can this Privacy Policy change?</h2>
          <h2 className="text-[#717171] font-poppinsRegular">
            Yes. We may revise this policy, update the KuberPe Platform, or
            change our services at any time. So, please check this policy
            regularly as it is binding on you.
            <br /> <br />
          </h2>
        </div>

        {/*THE INFORMATION WE COLLECT AND HOW WE USE IT */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
            1. THE INFORMATION WE COLLECT AND HOW WE USE IT
          </h2><br /><br />
          <div className=" flex md:flex-row flex-col">
            {/* What data do we collect */}
            <div className=" text-xl md:w-[50%]">
            
                <div className="md:w-[80%]">
                <h1 className="text-center text-xl font-poppinsBold">What data do we collect</h1><br /><br />
                <h2 className="font-poppinsSemibold">Registration and login data:</h2>
                <h2 className="text-[#717171]  font-poppinsRegular">Your name, mobile number, email, user-id, password, bank account details, or any information we ask you on the KuberPe Platform.</h2><br/><br/>

                <h2 className="font-poppinsSemibold">Log Data:</h2>
                <h2 className="text-[#717171] font-poppinsRegular ">Information we automatically collect when you use the KuberPe Platform, whether through the use of cookies, web beacons, log files, scripts, or any other information of similar kind.</h2><br/><br/>

                <h2 className="font-poppinsSemibold">Surveys:</h2>
                <h2 className="text-[#717171] font-poppinsRegular ">We may ask you to undertake surveys or fill questionnaires from time to time. Some of these will require you to give us personally identifiable information. These surveys or questionnaires may also be conducted by third-party service providers.</h2><br/><br/>

                <h2 className="font-poppinsSemibold">Device data:</h2>
                <h2 className="text-[#717171] font-poppinsRegular ">Information we collect from the devices you use to access or operate the KuberPe Platform. This includes, without limitation, information about your device attributes and identifiers, plugins, name of your mobile operator or ISP, SMS, device language, time zone, IP address, connection speed, device applications, contact list, or any information available on such device.</h2><br/><br/>

                <h2 className="font-poppinsSemibold">Financial information:</h2>
                <h2 className="text-[#717171] font-poppinsRegular ">This includes your bank account details, UPI-id, credit and debit card information, details regarding your payment wallets or other payment instruments.</h2><br/><br/>

                <h2 className="font-poppinsSemibold">Customer support data:</h2>
                <h2 className="text-[#717171] font-poppinsRegular ">Any information that you provide to our customer support team or our grievance redressal officer from time to time.</h2><br/><br/>

                <h2 className="font-poppinsSemibold">Location data:</h2>
                <h2 className="text-[#717171] font-poppinsRegular ">Information that we derive from your device or from the address details you provide us while using the KuberPe Platform.</h2></div>
            </div>
            {/* Why we collect it */}
            <div className=" md:w-[50%] ">
            <h1 className="text-center text-xl font-poppinsBold">Why we collect it</h1><br /> <br />
            <div className="font-poppinsRegular text-[#717171]"> <h2 className="text-xl">
                To set-up your account on the KuberPe Platform and facilitate
                login
                <br />
                To notify you about new products, discounts, and promotional
                offers
                <br />
                To notify you about changes to the KuberPe Platform, including
                the Terms and this Privacy Policy
                <br />
                To facilitate communication with you, and give customer support
                <br />
                To enforce our Terms, our rights, or the rights of our partner
                AMCs or Gold Suppliers
                <br />
                To develop new services, improve the existing services on the
                KuberPe Platform, and integrate user preferences, feedbacks and
                requests.
                <br />
                To administer the KuberPe Platform and facilitate internal
                operations, including but not limited to, troubleshooting, data
                analysis, testing, research, security, and fraud-detection;
                <br />
                To better understand how you use and access the KuberPe Platform
                and to improve your user experience;
                <br />
                To assess effectiveness of and improve advertising and other
                marketing and promotional activities;
                <br />
                To make your device compatible with the KuberPe Platform;
                <br />
                To customize and optimize your user experience on the KuberPe
                Platform.
                <br />
                To enable you to pay and process funds to our partner AMCs or
                Gold Supplier;
                <br />
                To disburse and distribute Rewards to your bank account;
                <br />
                To facilitate payment from our partner AMCs or Gold Supplier
                when you redeem or sell any financial product through the
                KuberPe Platform.
                <br />
                To investigate your support issue and assist you in resolving
                your query
                <br />
                To internally help us in improving and developing our user
                support systems;
                <br />
                To pass on relevant queries from users to our partner AMCs or
                Gold Supplier.
                <hr />
                <br />
                For security, fraud-detection and account management;
                <br />
                To provide you with location customization;
                <br />
                To detect if you are operating from a state where the use of our
                services or the KuberPe Platform is prohibited.
                <hr />
                <br />
                To analyze your credit worthiness, risk appetite, and curate
                financial products for you;
                <br />
                To help our partner AMCs and Gold Supplier verify your KYC with
                the use of third-party service providers.
                <hr />
                <br />
                To enable the KuberPe Platform and facilitation of our service;
                <br />
                To curate better financial products for you;
                <br />
                To adhere to our agreement with the partner AMCs and Gold
                Supplier, and comply with applicable law.
              </h2></div>
             
            </div>
          </div>
        </div>

        {/*DISCLOSURE OF YOUR INFORMATION */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
            2.DISCLOSURE OF YOUR INFORMATION <br /> <br />
          </h2>
          <ol className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[2rem] font-poppinsRegular">
            <li>
              Sharing with service providers: We work with third party service
              providers to execute various functionalities of the KuberPe
              Platform and we may share your information with such service
              providers to enable our services.
              <br /> <br />
            </li>
            <li>
              Sharing with partner AMCs/Gold Supplier: We will share your KYC
              data and financial information with our partner AMCs/Gold Supplier
              and their respective service providers. This helps the AMCs/Gold
              Supplier to ensure your KYC compliance, aid in the delivery and
              servicing of financial products, and enables them to disburse
              funds upon redemption.
              <br /> <br />
            </li>
            <li>
              Sharing with B2B partners: We partner with businesses to sponsor
              the Rewards and advertise relevant products to you. We share such
              information with these businesses that helps them to give you
              these Rewards and show you user-specific advertisements.
              <br /> <br />
            </li>
            <li>
              Sharing with group companies: Subject to applicable law, we may
              share any data we collect from you with our group companies for:
              product research and development, make our services better, and
              tailor the products for your benefit.
              <br /> <br />
            </li>
            <li>
              Sharing with law enforcement when needed: We will share your
              information, without prior notice, on a request from any
              governmental authority or law enforcement officers that we find
              appropriate or necessary in order to comply with applicable law.
              <br /> <br />
            </li>
          </ol>
        </div>

        {/* OUR DATA SECURITY PRACTICES */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
            3. OUR DATA SECURITY PRACTICES
            <br /> <br />
          </h2>
          <h2 className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[1.5rem] font-poppinsRegular">
            How we ensure security: We have in place appropriate technical and
            security measures to secure the information collected by us. We use
            the servers of third-party service providers to protect the
            information you provide us.
            <br /> <br />
            How you should ensure security: You are responsible to keep the user
            credentials for accessing the KuberPe Platform personal and
            confidential. You should not share your password or login details
            with anyone. We will not be responsible for any liability or
            obligation you might face due to your sharing of account details
            with anyone.
            <br /> <br />
            Disclaimer: Transmission of information via. the internet is not
            completely secure. We will do our best to protect your data,
            information and documents but we cannot guarantee the security
            absolutely. Any information you provide us is at your own risk and
            discretion. Once we have received your information, we will use
            strict procedures and security features to try to prevent
            unauthorized access.
            <br /> <br />
          </h2>
        </div>

        {/*OUR DATA RETENTION PRACTICES */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
            4.OUR DATA RETENTION PRACTICES
            <br />
            <br />
          </h2>
          <h2 className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[1.5rem] font-poppinsRegular">
            We may retain the information you provide us to facilitate smooth
            and uninterrupted use of the KuberPe Platform, facilitate our
            partner AMCs and Gold Suppliers to service you, or to comply with
            applicable laws (or our agreement with partner AMCs/Gold Supplier)
            that require us to hold your information for a specified time.
            <br />
            <br />
            We do not retain your information longer than required. For any
            other information, we will entertain your request for deletion,
            however, this might affect your smooth and uninterrupted use of our
            services.
            <br />
            <br />
          </h2>
        </div>

        {/* YOUR RIGHTS */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
            5.YOUR RIGHTS
            <br />
            <br />
          </h2>
          <h2 className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[1.5rem] font-poppinsRegular">
            You are free to remove or delete content from your KuberPe account
            at any point of time, subject to the Terms, this Privacy Policy, and
            applicable law.
            <br />
            <br />
            You may amend, add or delete your information from your KuberPe
            account at any time by logging in and visiting your profile page. As
            noted above, you may opt out of receiving marketing and promotional
            email communications from us. However, you will continue to receive
            necessary transactional emails until your account is deleted.
            <br />
            <br />
            You have a right to ask us to review, correct and amend the
            information we collect about you at any point in time. You also have
            the right to revoke your consent to the collection of your
            information going forward. We will entertain such requests to the
            extent they do not disrupt your obligation under the Terms. However,
            please note that revoking your consent may negatively affect your
            use of the KuberPe Platform.
            <br />
            <br />
            You can exercise any of these rights at any time by contacting us at{" "}
            <span className="text-[#2E8BFF]">manoj@kuberpe.com</span> . However,
            we require a reasonable period to comply with any of your requests.
            <br />
            <br />
          </h2>
        </div>

        {/* THIRD PARTY LINKS */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
            6.THIRD PARTY LINKS
            <br />
            <br />
          </h2>
          <h2 className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[1.5rem] font-poppinsRegular">
            The KuberPe Platform may contain references and links to third party
            websites and products in such websites. However, we are not liable
            in any manner, whatsoever, for any content as may be displayed on
            such third-party websites.
            <br />
            <br />
            If you follow a link to any of these websites, please note that
            these websites have their own privacy policies, terms of use and
            cookie policies that we do not accept any responsibility or
            liability for. Please check these documents before you submit any
            information to these websites, mobile applications or their own
            affiliates.
            <br />
            <br />
          </h2>
        </div>

        {/* COMMUNICATIONS FROM US*/}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
            7.COMMUNICATIONS FROM US
            <br />
            <br />
          </h2>
          <h2 className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[1.5rem] font-poppinsRegular">
            We may from time to time send you service-related announcements when
            we consider it necessary to do so (such as when we temporarily
            suspend the KuberPe Platform for maintenance, or security, privacy,
            or administrative-related communications). We will send these to you
            via SMS or/and emails.
            <br />
            <br />
            You may not opt-out of these service-related announcements, which
            are not promotional in nature and used solely to protect your
            account and keep you informed of important changes to the KuberPe
            Platform.
            <br />
            <br />
          </h2>
        </div>

        {/* GRIEVANCE OFFICER */}
        <div>
          <h2 className="font-poppinsBold md:text-3xl text-xl">
            6.GRIEVANCE OFFICER
            <br />
            <br />
          </h2>
          <h2 className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[1.5rem] font-poppinsRegular">
            We have a Grievance Officer to address your concerns regarding data
            safety, privacy, and your usage of the KuberPe Platform. We will
            resolve the issues raised by you within 30 (thirty) days from
            receiving them. You may contact our Grievance Officer using these
            coordinates:
            <br />
            <br />
            Name: Manoj Kumar
            <br /> Address: C-1/181 G/FLOOR KH.NO-1849 C-1BLOCK PHASE-IV. AYA
            NAGAR EXTN SOUTH DELHI-110047. Office hours: 10 am - 7pm (Monday to
            Friday)
            <br /> Email: 
            <span className="text-[#2E8BFF]">manoj@kuberpe.com</span>
            <br />
            <br />
          </h2>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
