import React from "react";
import { useNavigate } from "react-router";

const JobDetails = () => {
  const navigate = useNavigate();

  // const { id } = useParams();

  // const [jobDetails, setJobDetails] = useState({});

  //   useEffect(() => {
  //     if (id) {
  //       fetch(`http://localhost:8000/Jobs/${id}`)
  //         .then((res) => res.json())
  //         .then((d) =>setJobDetails(d));
  //     }
  //   }, [id]);

  const handleNavigate=(type)=>{
         if(type==="Form"){
          navigate("/form");
          window.scrollTo(0, 0);
         }
  }

  return (
    <div className="bg-custom-color1 text-white py-10 font-poppinsRegular">
      <div className="md:w-[60%] w-[90%] m-auto">
        <div className="py-20">
          <div className="items-center flex flex-col md:flex-row  justify-between ">
            <div>
              <h2 className="font-poppinsRegular md:text-2xl text-3xl cursor-pointer hover:text-[#7745ff] text-center">
                Android Developer(Mid/Senior)
              </h2>
              <h2 className="text-center md:text-left">
                FULL TIME--ON-SITE DELHI
              </h2>
            </div>
            <div className="mt-10 md:mt-0">
                <button
                  onClick={() => handleNavigate("Form")}
                  className="border border-white text-white h-10 w-60  rounded-lg transition duration-150 ease-in-out hover:text-[#7745ff] bg-custom-color1"
                >
                  APPLY FOR THIS JOB
                </button>
            </div>
          </div>
        </div>

        <div className=" w-full font-poppinsRegular">
          <h2 className="py-[2rem]">
            We're looking for driven and enthusiastic individuals. We want you
            to be comfortable being open to feedback and making rapid
            improvements; to enable and empower your peers; and finally, we want
            you to learn. KuberPe isn't paying you just to work, but also paying
            you to learn - you're bringing and taking something from the table.
          </h2>
          <h1 className="py-[2rem] font-poppinsBold">What is KuberPe?</h1>
          <h2 className="py-[2rem]">
            The KuberPe app is a habit-building micro-savings platform that
            helps users save fixed amounts from as little as ₹10, and our
            revolutionary round-off method effortlessly accumulates spare change
            from digital transactions and invests it in digital gold.
            <br />
            Founded by Mukesh Babu and Rishikesh Kumar Ray , KuberPe's
            unwavering mission is to shatter psychological barriers to saving
            and investing, thus spearheading the Financial Fitness revolution
            for Bharat.
          </h2>
          <h1 className="py-[2rem] font-poppinsBold">What does KuberPe do?</h1>
          <h2 className="py-[2rem]">
            With seamless ease of use, we provide users the ability to withdraw
            their savings directly to their bank accounts as cash or receive
            physical gold, which is delivered right to their doorstep.
            Furthermore, KuberPe empowers individuals with a flexible line of
            credit, starting at ₹1000, offering instant access to funds when
            needed.
          </h2>
          <h1 className="py-[2rem] font-poppinsBold">Responsibilities</h1>
          <ul className="list-disc p-[2rem]">
            <li>
              Owning significant architectural and product areas of the platform
            </li>
            <li>
              You'll make the key decisions and see them through to successful
              user engagement
            </li>
            <li>
              Delivering features that make a big impact on our growth journey
            </li>
            <li>Writing high-quality, highly-performant code</li>
            <li>Pushing changes every week</li>
          </ul>
          <h2 className="py-[2rem] font-poppinsBold">Requirements</h2>
          <ul className="list-disc p-[2rem]">
            <li>
              An expert with Kotlin, Kotlin multiplatform and the rest of the
              Android ecosystem
            </li>
            <li>Collaborative and want to see the team succeed in its goals</li>
            <li>Practical about tradeoffs between speed and polish</li>
            <li>Passionate about showing others how to do something new</li>
            <li>Excited to join a small team that moves quickly</li>
            <li>
              2 - 5 years of professional Android development experience in
              consumer products
            </li>
          </ul>
          <h2 className="py-[2rem] font-poppinsBold">
            Why join KuberPe, you ask?{" "}
          </h2>
          <h2 className="py-[2rem]">
            KuberPe is a diverse and inclusive workplace which embraces ideas
            and perspectives of all employees, and recognises their efforts and
            contributions regardless of gender or ethnicity. Our workspace also
            boasts a great blend of youth and experience, which helps us
            maintain a transparent culture with no micromanagement. We also have
            flexible working hours, and provide medical insurance to all
            employees.
          </h2>
        </div>
        <div className="items-center flex justify-center my-10">
            <button onClick={() => handleNavigate("Form")} className="border border-white text-white h-10 w-60 rounded-lg transition duration-150 ease-in-out hover:text-[#7745ff] bg-custom-color1">
              APPLY FOR THIS JOB
            </button>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
