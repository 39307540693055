import React from "react";
import {useNavigate } from "react-router-dom";
import FaqMiddle from "./FaqMiddle";

const Faq = () => {
  const navigate=useNavigate()

  const faqData = [
    {
      id: 1,
      question: "What is KuberPe?",
      answer:
        "KuberPe is a digital gold & P2P based savings application for users to save and grow their money and earn weekly rewards just by saving in secure assets.",
    },
    {
      id: 2,
      question: "Is KuberPe App safe to use?",
      answer:
        "KuberPe app is 100% safe and secure to use for your Daily Savings & Investments in Gold. It is powered by Augmont Gold and all payments happen over secure banking networks.",
    },
    {
      id: 3,
      question: "How does KuberPe work?",
      answer:
        "For every Rupee saved on the application in secure assets, users get KuberPe tokens, which can be used to play on the application and win rewards. On the other hand, for every Rs. 500 saved on the app, earn a weekly ticket and stand a chance to win more rewards.",
    },
    {
      id: 4,
      question: "How to download the KuberPe App?",
      answer:
        "We are available on both Android Play Store and iOS App Store and all you need to do is search for KuberPe and download the app within a minute.",
    },
    {
      id: 5,
      question: "Does KuberPe charge any fees?",
      answer:
        "KuberPe doesn't charge any fee on the app. But there is a 3% GST incurred on digital gold as per the RBI norms.",
    },
    {
      id: 6,
      question: "How does KuberPe give 10% - 12% in returns?",
      answer:
        "KuberPe Kuber JI is a peer to peer investment asset where users get to earn upto 12% returns. This product is offered under the partnership of Lendbox which is a SEBI registered P2P company.",
    },
    // Add more FAQ items as needed
  ];

  const handleNavigate=(type)=>{
    if(type==='faq'){
      navigate('/faq')
      window.scrollTo(0,0)
    }
  }

  return (
    <div className=" bg-richblack-900 text-white text-center font-poppinsRegular">
      <div className="w-[85%] m-auto py-5">

        <div className=" md:h-60 h-32  flex flex-col gap-3 items-center justify-center font-poppinsRegular">
          <h1 className="font-poppinsBold md:text-5xl text-xl">
            Frequently Asked Questions
          </h1>
          <h2 className="font-poppinsBold  text-[#717171]">You asked, we answered</h2>
        </div>

        <div className="text-left">
          {faqData.map((item) => (
            <FaqMiddle item={item}/>
          ))}
        </div>
        
        <div onClick={()=>{handleNavigate('faq')}} className="h-12 w-48 m-auto font-poppinsRegular">
          <button className="text-lg rounded-lg bg-custom-color1 border border-[#7745ff] h-[100%] w-[100%] hover:bg-[#7745ff] active:bg-[#7745ff]">
            Read More
          </button>
        </div>

      </div>
    </div>
  );
};

export default Faq;
