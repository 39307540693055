import React, { useState } from "react";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaApple } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import {useLocation, useNavigate } from "react-router-dom";
import kuberPeLogo from "../assets/images/kuberpelogo.png"

const Footer = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const openPlayStore = () => {
    // URL for your app on the Play Store
    const playStoreUrl = "http://app.kuberpe.com/refer/SRK892";
    window.open(playStoreUrl, "_blank");
  };

  const isHomeRoute = location.pathname === '/';

  const [showTooltip, setShowTooltip] = useState(false);

  const handleNavigate = (type) => {
    switch (type) {
      case "Home":
        navigate("/");
        window.scrollTo(0, 0);
        break;
      case "About-us":
        navigate("/aboutus");
        window.scrollTo(0, 0);
        break;
      case "Digital-Gold":
        navigate("/dgold");
        window.scrollTo(0, 0);
        break;
      case "Gold-Pro":
        navigate("/goldpro");
        window.scrollTo(0, 0);
        break;
      case "Kuberpe-Plus":
        navigate("/kuber-plus");
        window.scrollTo(0, 0);
        break;
      case "Gold-Plus":
        navigate("/gold-plus");
        window.scrollTo(0, 0);
        break;
      case "Kuber-Ji":
        navigate("/kuberji");
        window.scrollTo(0, 0);
        break;
      case "Carriers":
        navigate("/careers");
        window.scrollTo(0, 0);
        break;
      case "Instant-Loans":
        navigate("/instantloans");
        window.scrollTo(0, 0);
        break;
      case "What is Digital Gold":
        navigate("/digitalgold");
        window.scrollTo(0, 0);
        break;
      case "Partner-Program":
        navigate("partnerprogram");
        window.scrollTo(0, 0);
        break;
      case "Check gold price":
        navigate("/check-live-gold-price");
        window.scrollTo(0, 0);
        break;
      case "FAQ":
        navigate("/faq");
        window.scrollTo(0, 0);
        break;
      case "Terms":
        navigate("/terms");
        window.scrollTo(0, 0);
        break;
      case "Privacy-Policy":
        navigate("/privacypolicy");
        window.scrollTo(0, 0);
        break;
      case "Refund":
        navigate("/refunds");
        window.scrollTo(0, 0);
        break;
      case "Blogs":
        navigate("/blogs");
        window.scrollTo(0, 0);
        break;
        case "contactsupport":
        navigate("/contactsupport");
        window.scrollTo(0, 0);
        break;
        case "Ismspolicy":
        navigate("/ismspolicy");
        window.scrollTo(0, 0);
        break;
        case "Grievanceredressal":
        navigate("/grievanceredressal");
        window.scrollTo(0, 0);
        break;

        case "goldsip":
          navigate("/goldsip");
          window.scrollTo(0, 0);
          break;
          case "dailysavings":
          navigate("/dailysavings");
          window.scrollTo(0, 0);
          break;
          case "emi-gyan":
          navigate("/emi-gyan");
          window.scrollTo(0, 0);
          break;
          case "sitemap":
          navigate("/sitemap");
          window.scrollTo(0, 0);
          break;
          case "disclaimer":
          navigate("/disclaimer");
          window.scrollTo(0, 0);
          break;
          case "spotplatform":
          navigate("/spot-platform");
          window.scrollTo(0, 0);
          break;
      default:
        navigate("/");
        window.scrollTo(0, 0);
        break;
    }
  };

  return (
    <footer className=" bg-richblack-900  text-white">
      <div className="w-[85%] m-auto pt-[2rem]">

        <div className=" flex flex-col md:flex-row md:justify-between gap-10 border-t border-b border-richblue-500 font-poppinsRegular">

          <div className="md:w-[50%] xs:w-full text-left">


            {/* logo */}
            <div onClick={() => handleNavigate("Home")} className="mt-5">
              <img src={kuberPeLogo} alt="" className="h-20 w-80" style={{objectFit:"contain"}}/>
            </div>


            {/* content */}
            <div className="md:w-[62%] h-32 flex items-center text-[#717171]">
              <h2>
                KuberPe, a platform used to encourage savings habits in Indians
                by helping them save on a daily basis.
              </h2>
            </div>


            {/* social media */}
            <div className="flex md:w-[70%] w-full gap-7">
              <div className="">
                <a href="https://www.instagram.com/kuberpe_now/" target="blank">
                  {" "}
                  <FaInstagram style={{ height: "40px", width: "40px" }} />
                </a>
              </div>
              <div>
                <a href="https://www.facebook.com/kuberpe/" target="blank">
                  {" "}
                  <FaFacebook style={{ height: "40px", width: "40px" }} />
                </a>
              </div>
              <div>
                <a
                  href="https://www.linkedin.com/company/99000719/admin/feed/posts/"
                  target="blank"
                >
                  {" "}
                  <FaLinkedin style={{ height: "40px", width: "40px" }} />
                </a>
              </div>
              <div>
                <a href="https://twitter.com/gold_kuberpe" target="blank">
                  {" "}
                  <FaTwitter style={{ height: "40px", width: "40px" }} />
                </a>
              </div>

              <div>
                <a href="https://www.youtube.com/@kuberpe" target="blank">
                  {" "}
                  <FaYoutube style={{ height: "40px", width: "40px" }} />
                </a>
              </div>
            </div>


            {/* google play apple */}
            <div className=" w-[100%] md:w-[80%] h-20 flex gap-3 my-5 items-center cursor-pointer">
              
                <div onClick={openPlayStore} className="h-12 w-60 border rounded-lg flex items-center justify-center">
                  <img
                    src="./images/playstore.png"
                    alt="google"
                    className="w-10 h-10"
                    style={{objectFit:"contain"}}
                  />
                  <div className="text-white leading-3">
                    <p className="md:text-sm text-xs text-gray-400">Get It On</p>
                    <p className="md:text-sm text-xs">Google Play</p>
                  </div>
                </div>
              

              <div
                className="h-12 w-60 border rounded-lg flex items-center justify-center"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <FaApple style={{ height: "30px", width: "30px" }} />
                <div className="text-white leading-3">
                  <p className="md:text-sm text-xs text-gray-400">Download on </p>
                  <p className="md:text-sm text-xs">App Store</p>
                </div>
                {showTooltip && (
                  <span className="absolute bg-[#7745ff] text-white text-md py-1 px-6 rounded-md mt-24 ml-44 opacity-100 transition-opacity duration-300">
                    Comming Soon....
                  </span>
                )}
              </div>
            </div>
            
          </div>

          {/* all pages */}
          <div className="flex lg:justify-around  py-5 justify-between w-full md:w-[50%] leading-8 ">
            <div className="text-left">
              <div className="text-2xl">KuberPe</div>
              <div
                onClick={() => handleNavigate("About-us")}
                className="text-[#717171] hover:text-white cursor-pointer  active:text-white  "
              >
                About Us
              </div>
              <div
                onClick={() => handleNavigate("Digital-Gold")}
                className="text-[#717171] hover:text-white cursor-pointer  active:text-white   "
              >
                Digital Gold
              </div>

              <div
                onClick={() => handleNavigate("Gold-Pro")}
                className="text-[#717171] hover:text-white cursor-pointer  active:text-white   "
              >
                Gold Pro
              </div>
              <div style={{display:"none"}}
                onClick={() => handleNavigate("Kuberpe-Plus")}
                className="text-[#717171] hover:text-white cursor-pointer  active:text-white   "
              >
                Kuber Plus
              </div>
              <div style={{display:"none"}}
                onClick={() => handleNavigate("Gold-Plus")}
                className="text-[#717171] hover:text-white cursor-pointer  active:text-white   "
              >
                Gold Plus
              </div>
              <div
                onClick={() => handleNavigate("Kuber-Ji")}
                className="text-[#717171] hover:text-white cursor-pointer  active:text-white   "
              >
                Kuber JI
              </div>
              <div style={{display:"none"}}
                onClick={() => handleNavigate("goldsip")}
                className="text-[#717171] hover:text-white cursor-pointer  active:text-white   "
              >
                Gold SIP
              </div>
              <div
                onClick={() => handleNavigate("Carriers")}
                className="text-[#717171] hover:text-white cursor-pointer  active:text-white   "
              >
                Careers
              </div>
            </div>

            <div className="text-left">
              <div className="text-2xl">Explore</div>
              <div style={{display:"none"}}
                onClick={() => handleNavigate("Instant-Loans")}
                className="text-[#717171] hover:text-white cursor-pointer  active:text-white"
              >
                Instant Loans
              </div>
              <div
                onClick={() => handleNavigate("What is Digital Gold")}
                className="text-[#717171] hover:text-white cursor-pointer  active:text-white"
              >
                What is Digital Gold ?
              </div>
              <div style={{display:"none"}}
                onClick={() => handleNavigate("Partner-Program")}
                className="text-[#717171] hover:text-white cursor-pointer  active:text-white"
              >
                Partner Programs
              </div>
              <div
                onClick={() => handleNavigate("Check gold price")}
                className="text-[#717171] hover:text-white cursor-pointer  active:text-white"
              >
                Check Gold Price
              </div>
              <div style={{display:"none"}}
                onClick={() => handleNavigate("dailysavings")}
                className="text-[#717171] hover:text-white cursor-pointer  active:text-white"
              >
                Daily Saving
              </div>
              <div style={{display:"none"}}
                onClick={() => handleNavigate("emi-gyan")}
                className="text-[#717171] hover:text-white cursor-pointer  active:text-white"
              >
                EMIygyan
              </div>
              <div
                onClick={() => handleNavigate("FAQ")}
                className="text-[#717171] hover:text-white cursor-pointer  active:text-white"
              >
                FAQS
              </div>
              <div
                onClick={() => handleNavigate("Blogs")}
                className="text-[#717171] hover:text-white cursor-pointer  active:text-white"
              >
                Blogs
              </div>
            </div>
          </div>
        </div>

        {/* address div */}
        <div className="border-b border-richblue-500 md:mt-6 md:pb-0 pb-[0.5rem] flex flex-col md:flex-row md:justify-between gap-10 md:h-32 font-poppinsRegular">
          <div className="md:w-[50%] w-[100%] ">
            <div className="text-left ">
              <h1 className="text-xl py-2">Address</h1>
              <h2 className="text-[#717171] text-sm">
                C-1/181 G/FLOOR KH.NO-1849 C-1BLOCK PHASE-IV. AYA NAGAR
                EXTN SOUTH DELHI-110047
              </h2>
            </div>
          </div>
          <div className=" flex md:justify-around justify-between w-full md:w-[50%] leading-8 ">
            <div className="text-left ">
              <div
                onClick={() => handleNavigate("Privacy-Policy")}
                className="text-[#717171] hover:text-white cursor-pointer  active:text-white "
              >
                Privacy Policy
              </div>
              <div
                onClick={() => handleNavigate("Terms")}
                className="text-[#717171] hover:text-white cursor-pointer  active:text-white "
              >
                Terms of Use
              </div>
            </div>

            <div className="text-left">
                <div onClick={() => handleNavigate("contactsupport")} className="text-[#717171] hover:text-white cursor-pointer  active:text-white ">
                  Contact Support
                </div>              <div
                onClick={() => handleNavigate("Refund")}
                className="text-[#717171] hover:text-white cursor-pointer  active:text-white "
              >
                Refund Policy
              </div>
                <div style={{display:"none"}} onClick={() => handleNavigate("Ismspolicy")} className="text-[#717171] hover:text-white cursor-pointer  active:text-white ">
                  ISMS Policy
                </div>
                <div style={{display:"none"}} onClick={() => handleNavigate("Grievanceredressal")} className="text-[#717171] hover:text-white cursor-pointer  active:text-white">
                  Grievance Redressal
                </div>
            </div>
          </div>
        </div>

        <div className=" md:h-22 font-poppinsRegular md:py-2 py-4">
          <div className="flex items-center md:justify-end justify-between w-[100%] gap-[1.5rem]" style={{display:"none"}}>
              <div onClick={() => handleNavigate("sitemap")} className="text-[#717171] hover:text-white cursor-pointer  active:text-white ">
                SiteMap
                </div>
                <div onClick={() => handleNavigate("disclaimer")} className="text-[#717171] hover:text-white cursor-pointer  active:text-white ">
                  Disclaimer
                </div>
                <div onClick={() => handleNavigate("spotplatform")} className="text-[#717171] hover:text-white cursor-pointer  active:text-white">
                  SPOT Platform
                </div>
          </div>
          <div className="py-[1rem]"><p className="text-[#717171] text-center mt-[2rem] md:mt-0">© Dazala Tech Private limited</p></div>
        </div>

      </div>

    </footer>
  );
};

export default Footer;
