import React, { useState,useEffect } from "react";
import jobsData from "../pages/Careers/Jobs.json";
import { BiSolidUpArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

const Jobs = () => {
  const [isAutoplay, setIsAutoplay] = useState(true);
  const [hideArrows, setHideArrows] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Disable autoplay for medium (md) screens and above
      setIsAutoplay(window.innerWidth < 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setHideArrows(window.innerWidth >= 1024); // Hide arrows for medium and larger screens
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: isAutoplay,
    autoplaySpeed: 3000,
    arrows: !hideArrows, // Show arrows unless hideArrows is true

    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          prevArrow: false, // Hide arrows for md screens
          nextArrow: false, // Hide arrows for md screens
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          prevArrow: false, // Hide arrows for md screens
          nextArrow: false, // Hide arrows for md screens
        },
      },
    ],
  };

  let navigate = useNavigate();
  const { All } = jobsData;
  const { Product } = jobsData;
  const { Engineering } = jobsData;
  const { Design } = jobsData;
  const { Marketing } = jobsData;

  const [all] = useState(All);
  const [product] = useState(Product);
  const [engineering] = useState(Engineering);
  const [design] = useState(Design);
  const [marketing] = useState(Marketing);

  const [queChange, setQueChange] = useState(All);

  const handleClick = () => {
    navigate("/apply");
  };

  return (
    <div className="bg-richblack-900  text-white">
      <section className="w-[85%] m-auto mt-20  font-poppinsRegular">
        <h2 className="text-xl text-center text-pure-greys-500">Openings</h2>
        <h1 className="md:text-5xl text-[#ffda2d] text-2xl font-poppinsBold text-center p-10 ">
          Looking out?
        </h1>
        <h2 className="md:text-5xl text-3xl text-center font-poppinsSemibold">
          So are we for these roles below.
        </h2>
        
        <div className="my-20 m-auto">
          <Slider {...settings}style={{paddingLeft:"8%"}}>
            <div className="">
            <button
            id="contentmarket"
            value="contentmarketing"
            onClick={() => {
              setQueChange(all);
            }}
            className="h-14 w-32 text-lg md:font-poppinsBold rounded-lg bg-custom-color1 border border-[#7745ff] hover:bg-[#7745ff] focus:bg-[#7745ff]"
          >
            All
          </button>
            </div>
            <div>
            <button
            id="communicate"
            value="communication"
            onClick={() => {
              setQueChange(product);
            }}
            className="h-14 w-32 text-lg md:font-bold rounded-lg bg-custom-color1 border border-[#7745ff] hover:bg-[#7745ff] focus:bg-[#7745ff]"
          >
            Product
          </button>
            </div>
            <div>
            <button
            id="promotion"
            value="promotional"
            onClick={() => {
              setQueChange(engineering);
            }}
            className="h-14 w-32 text-lg md:font-bold rounded-lg bg-custom-color1 border border-[#7745ff] hover:bg-[#7745ff] focus:bg-[#7745ff]"
          >
            Engineering
          </button>
            </div>
            <div>
            <button
            id="people"
            value="people"
            onClick={() => {
              setQueChange(design);
            }}
            className="h-14 w-32 text-lg md:font-bold rounded-lg bg-custom-color1 border border-[#7745ff] hover:bg-[#7745ff] focus:bg-[#7745ff]"
          >
            Design
          </button>
            </div>
            <div>
            <button
            id="local"
            value="localization"
            onClick={() => {
              setQueChange(marketing);
            }}
            className="h-14 w-32 text-lg md:font-bold rounded-lg bg-custom-color1 border border-[#7745ff] hover:bg-[#7745ff] focus:bg-[#7745ff]"
          >
            Marketing
          </button>
            </div>
          </Slider></div>

        <div className="">
          {queChange.map((item, i) => {
            return (
              <div key={item.id} className="mb-4">
                <div className="cursor-pointer p-[1.5rem] flex justify-between items-center font-poppinsRegular px-4 bg-custom-color1 rounded-tl-lg rounded-tr-lg">
                  <div className="w-[80%]">
                    <h2 className="font-poppinsSemibold">{item.question}</h2>
                  </div>
                  <div>
                    <span className="">
                      <BiSolidUpArrow />
                    </span>
                  </div>
                </div>
                <div className="text-left bg-custom-color1  p-[1rem] rounded-bl-lg rounded-br-lg">
                  <p>{item.answer}</p>
                  <button
                    onClick={handleClick}
                    className="border my-[1rem] h-14 w-32 rounded-2xl transition duration-150 ease-in-out hover:text-[#7745ff]"
                  >
                    Check All
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default Jobs;
