import React from "react";
import FaqMiddle from "../components/FaqMiddle";
import Marquee from "react-fast-marquee";
import augmont from "../assets/images/augmont-logo.jpg"
import lendbox from "../assets/images/lendbox.jpg"
import LazyImage from "../components/LazyImage";
import Navbar from "../components/Navbar";

const ILoans = () => {

  const openPlayStore = () => {
    // URL for your app on the Play Store
    const playStoreUrl = 'http://app.kuberpe.com/refer/SRK892';
    window.open(playStoreUrl, '_blank');
};


  const faqData = [
    {
      id: 1,
      question: "What is Instant Loan?",
      answer:
        "Instant loan is a pre-approved credit line product that provides quick & easy access to cash.",
    },
    {
      id: 2,
      question: "What is an e-mandate?",
      answer:
        "Kuber is an Automated Investment app that lets you Save and Invest money into Digital Gold. It invests spare change from your online transactions into Digital Gold, automatically. Kuberpe is the first Made in India app to come up with an Innovative Solution to Save Money Daily and Invest in Digital Gold. E-Mandate gives permission to the lending partner to automatically deduct EMI from your linked bank account. It simplifies the EMI payment by making sure your payments are made on time.",
    },
    {
      id: 3,
      question: "Do I need any documents during the process?",
      answer:
        "No,it is a paperless process. But we do require some details to verify your identity. Please keep following details handy:1.PAN Card 2.Aadhaar Card 3.Bank Details Please Note:PAN card & bank account holder must be same for successful loan application.",
    },
    {
      id: 4,
      question: "When can I receive cash in my bank account?",
      answer:
        "You will receive cash in your bank account instantly. We ensure immediate processing of transactions. There could be delays only if it is a public holiday or a weekend",
    },
    // Add more FAQ items as needed
  ];

  return (
    <div className="bg-richblack-900 text-white">
      <Navbar backgroundColour={'bg-richblack-900'}/>
      <div className="w-[85%] m-auto mt-10">
        {/* first section */}
        <div className="items-center flex lg:flex-row flex-col-reverse gap-10 font-poppinsRegular">
          {/* get instant loans */}
          <div className="lg:w-[60%] ">
            <div className=" font-poppinsSemibold hidden md:flex gap-2 mb-[1rem]">
              <img src="./images/flag.svg" alt="flag" className="" style={{objectFit:"contain"}}/>
              <h2 className="text-[#f8d969]">
                India's most loved financial partner
              </h2>
            </div>
            <div className="w-[90%]">
              <h1 className="font-poppinsBold md:text-6xl text-3xl md:my-[1rem]">
                Get Instant Loans Up to 1 Lakhs
              </h1>
            </div>
            <div>
              <h2 className="text-xl md:my-[2rem] my-[1rem] text-[#717171] font-poppinsSemibold">
                One stop solution for all your money needs!
              </h2>
            </div>
              <div className="mt-[2rem] flex justify-center md:justify-start">
                <button onClick={openPlayStore} className=" md:w-[75%] w-[80%] items-center h-14 flex justify-center rounded-lg bg-[#7745ff] font-poppinsSemibold">
                  Download KuberPe App{" "}
                  <img
                    src="./images/playstore.png"
                    alt="playstore"
                    className="w-10 h-10"
                    style={{objectFit:"contain"}}
                  />
                </button>
              </div>
          </div>
          {/* loans image */}
          <div className="md:w-[50%] w-[100%]">
          <LazyImage src={"./images/instnt loan.png"} alt={"instnt"}/>
            {/* <img src="./images/instnt loan.png" alt="lendingbanner"  /> */}
          </div>
        </div>

        {/* second section */}
        <div className="font-poppinsRegular mt-24">
          <div className="items-center flex flex-col justify-center">
            <h1 className="md:text-6xl text-3xl font-poppinsBold pb-10">
              Benefits You Get
            </h1>
            <h2 className="md:text-2xl text-xl text-center text-[#737373] pb-10">
              Enjoy the benefits KuberPe provides which increase your access
              loans.
            </h2>
          </div>

          <div className="mt-10 items-center grid grid-cols-2 md:grid-cols-4 md:gap-3">
            <div className=" text-center items-center flex flex-col">
              <img
                src="./images/ruppee.svg"
                alt="ruppee"
                className="h-12 w-12 mb-5"
                style={{objectFit:"contain"}}
              />
              <h1 className="text-xl font-poppinsSemibold mb-3">
                Instant Money Transfer
              </h1>
              <h2 className="text-[#737373] font-poppinsSemibold hidden md:flex">
                No more waiting to get loan money in yoyr account.
              </h2>
            </div>
            <div className=" text-center items-center flex flex-col">
              <img
                src="./images/cal.svg"
                alt="calender"
                className="h-10 w-10 mb-5"
                style={{objectFit:"contain"}}
              />
              <h1 className="text-xl font-poppinsSemibold mb-3">
                Flexible EMIs
              </h1>
              <h2 className="text-[#737373] font-poppinsSemibold hidden md:flex">
                Multiple EMI tenures ranging from 3 to 60 months.
              </h2>
            </div>
            <div className=" text-center items-center flex flex-col">
              <img
                src="./images/docoff.svg"
                alt="docoff"
                className="h-10 w-10 mb-5"
                style={{objectFit:"contain"}}
              />
              <h1 className="text-xl font-poppinsSemibold mb-3">
                Paperless Application
              </h1>
              <h2 className="text-[#737373] font-poppinsSemibold hidden md:flex">
                100% digital and hassle free process to cater your needs
              </h2>
            </div>
            <div className=" text-center items-center flex flex-col">
              <img src="./images/lock.svg" alt="lock" className="h-10 w-10 mb-5" style={{objectFit:"contain"}}/>
              <h1 className="text-xl font-poppinsSemibold mb-3">
                Safe & Secure Process
              </h1>
              <h2 className="text-[#737373] font-poppinsSemibold hidden md:flex">
                Ensure fully safe amount transfer to your bank account
              </h2>
            </div>
          </div>
        </div>

        {/* forth section */}
        <div className="font-poppinsRegular mt-24">
          <div className="items-center flex flex-col justify-center">
            <h1 className="md:text-5xl text-2xl text-center font-bold">
              Why Choose KuberPe
            </h1>
            <h2 className="text-xl text-center md:hidden text-[#737373]">
              The features Kuberpe provides, makes taking a loan hassle free for
              you.
            </h2>
          </div>


          <Marquee pauseOnHover speed={100}>
          <div className="md:flex md:flex-row gap-7 hidden  flex-col justify-around mt-20">
            <div className="md:w-64 md:h-52 md:bg-custom-color1 rounded-3xl flex md:flex-col flex-row items-center md:justify-center gap-3 py-2 md:py-0">
              <img
                src="./images/Signed.svg"
                alt="Signed"
                className="h-12 w-12 rounded-full md:flex hidden"
                style={{objectFit:"contain"}}
              />
              <img
                src="./images/Tick.svg"
                alt="Tick"
                className="h-8 w-8 rounded-full md:hidden"
                style={{objectFit:"contain"}}
              />
              <h1 className="md:text-xl font-poppinsBold text-center md:p-5">
                Take loan amount of your choice
              </h1>
            </div>
            <div className="md:w-64 md:h-52 md:bg-custom-color1 rounded-3xl flex md:flex-col flex-row items-center md:justify-center gap-3 py-2 md:py-0">
              <img
                src="./images/Signed.svg"
                alt="Signed"
                className="h-12 w-12 rounded-full md:flex hidden"
                style={{objectFit:"contain"}}
              />
              <img
                src="./images/Tick.svg"
                alt="Tick"
                className="h-8 w-8 rounded-full md:hidden"
                style={{objectFit:"contain"}}
              />
              <h1 className="md:text-2xl font-poppinsBold text-center md:p-5">
                Borrow multiple times
              </h1>
            </div>
            <div className="md:w-64 md:h-52 md:bg-custom-color1 rounded-3xl flex md:flex-col flex-row items-center md:justify-center gap-3 py-2 md:py-0">
              <img
                src="./images/Signed.svg"
                alt="Signed"
                className="h-12 w-12 rounded-full md:flex hidden "
                style={{objectFit:"contain"}}
              />
              <img
                src="./images/Tick.svg"
                alt="Tick"
                className="h-8 w-8 rounded-full md:hidden"
                style={{objectFit:"contain"}}
              />
              <h1 className="md:text-2xl font-poppinsBold text-center md:p-5">
                Affordable interest rates
              </h1>
            </div>
            <div className="md:w-64 md:h-52 md:bg-custom-color1 rounded-3xl flex md:flex-col flex-row items-center md:justify-center gap-3 py-2 md:py-0 mr-5">
              <img
                src="./images/Signed.svg"
                alt="Signed"
                className="h-12 w-12 rounded-full md:flex hidden"
                style={{objectFit:"contain"}}
              />
              <img
                src="./images/Tick.svg"
                alt="Tick"
                className="h-8 w-8 rounded-full md:hidden"
                style={{objectFit:"contain"}}
              />
              <h1 className="md:text-2xl font-poppinsBold text-center md:p-5">
                Easy repayment options
              </h1>
            </div>
          </div> </Marquee>

        {/* small screen */}
          <div className="md:hidden flex md:flex-row gap-5  flex-col justify-around md:py-20 ">
            <div className="md:w-64 md:h-52 md:bg-custom-color1 rounded-3xl flex md:flex-col flex-row items-center md:justify-center gap-3 py-2 md:py-0">
              <img
                src="./images/Signed.svg"
                alt="Signed"
                className="h-12 w-12 rounded-full md:flex hidden"
                style={{objectFit:"contain"}}
              />
              <img
                src="./images/Tick.svg"
                alt="Tick"
                className="h-8 w-8 rounded-full md:hidden"
                style={{objectFit:"contain"}}
              />
              <h1 className="md:text-xl font-poppinsBold text-center md:p-5">
                Take loan amount of your choice
              </h1>
            </div>
            <div className="md:w-64 md:h-52 md:bg-custom-color1 rounded-3xl flex md:flex-col flex-row items-center md:justify-center gap-3 py-2 md:py-0">
              <img
                src="./images/Signed.svg"
                alt="Signed"
                className="h-12 w-12 rounded-full md:flex hidden"
                style={{objectFit:"contain"}}
              />
              <img
                src="./images/Tick.svg"
                alt="Tick"
                className="h-8 w-8 rounded-full md:hidden"
                style={{objectFit:"contain"}}
              />
              <h1 className="md:text-2xl font-poppinsBold text-center md:p-5">
                Borrow multiple times
              </h1>
            </div>
            <div className="md:w-64 md:h-52 md:bg-custom-color1 rounded-3xl flex md:flex-col flex-row items-center md:justify-center gap-3 py-2 md:py-0">
              <img
                src="./images/Signed.svg"
                alt="Signed"
                className="h-12 w-12 rounded-full md:flex hidden "
                style={{objectFit:"contain"}}
              />
              <img
                src="./images/Tick.svg"
                alt="Tick"
                className="h-8 w-8 rounded-full md:hidden"
                style={{objectFit:"contain"}}
              />
              <h1 className="md:text-2xl font-poppinsBold text-center md:p-5">
                Affordable interest rates
              </h1>
            </div>
            <div className="md:w-64 md:h-52 md:bg-custom-color1 rounded-3xl flex md:flex-col flex-row items-center md:justify-center gap-3 py-2 md:py-0 mr-5">
              <img
                src="./images/Signed.svg"
                alt="Signed"
                className="h-12 w-12 rounded-full md:flex hidden"
                style={{objectFit:"contain"}}
              />
              <img
                src="./images/Tick.svg"
                alt="Tick"
                className="h-8 w-8 rounded-full md:hidden"
                style={{objectFit:"contain"}}
              />
              <h1 className="md:text-2xl font-poppinsBold text-center md:p-5">
                Easy repayment options
              </h1>
            </div>
          </div>

          

          <a href="http://app.kuberpe.com/refer/SRK892 ">
            <div className=" flex justify-center my-10">
              <button className=" w-[100%] items-center h-14 flex justify-center rounded-lg bg-[#7745ff] font-poppinsSemibold md:hidden">
                Download KuberPe App{" "}
                <img
                  src="./images/playstore.png"
                  alt="playstore"
                  className="w-10 h-10"
                  style={{objectFit:"contain"}}
                />
              </button>
            </div>
          </a>
        </div>

        {/* fifth section */}
        <div className="font-poppinsRegular mt-20">
          <div className="items-center flex flex-col justify-center">
            <h1 className="md:text-5xl text-2xl text-center font-poppinsBold">
              Our Trusted Partners
            </h1>
            <h2 className="text-2xl text-center text-[#737373]">
              Get safe and secure services with the best trusted service
              providers
            </h2>
          </div>

          <div className="items-center flex justify-center gap-10 flex-col md:flex-row  p-10">
            {/* First div */}
            <div className="flex flex-col items-center ">
              <div className="h-36 w-36"><img
                src={augmont}
                alt="creditsasion-dweb"
                className=" mb-2  md:flex h-full w-full"
                style={{objectFit:"contain"}}
              /></div>
              <button className=" text-white text-2xl rounded font-poppinsSemibold mt-5 pt-2 px-4 h-20 w-36 border">
               Augmont
              </button>
            </div>

            {/* Second div */}
            <div className="flex flex-col items-center ">
              <div className="h-36 w-36"><img
                src={lendbox}
                alt="lendbox"
                className=" mb-2 md:flex h-full w-full"
                style={{objectFit:"contain"}}
              /></div>
              <button className=" text-white text-2xl rounded font-poppinsSemibold pt-2 px-4 mt-5 h-20 w-36 border">
                Lendbox
              </button>
            </div>
          </div>

          <div className="text-2xl text-center">
            <h2>
              For Grievances you can alse visit :
                <a href="https://sachet.rbi.org.in/">
                https://sachet.rbi.org.in/
              </a>
            </h2>
          </div>
        </div>

        {/* sixth section */}
        <div className="mt-24 pb-16 font-poppinsRegular">
          <div className=" items-center flex flex-col gap-3  justify-center">
            <h1 className="font-poppinsBold text-center md:text-5xl text-xl">
              Frequently Asked Questions
            </h1>
            <h2 className="font-poppinsBold  text-[#717171] py-10">
              You asked, we answered
            </h2>
          </div>

          <div className="text-left">
            {faqData.map((item) => (
              <FaqMiddle item={item} />
            ))}
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default ILoans;
