import React, { useRef, useEffect, useState } from 'react';

function LazyImage({ src, alt, imgProperty }) {
  const imgRef = useRef();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(imgRef.current);
        }
      },
      { threshold: 0.5 }
    );

    observer.observe(imgRef.current);

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  return <img ref={imgRef} src={isVisible ? src : ''} alt={alt} className={`${imgProperty} h-[100%] w-[100%] subpixel-antialiased`} style={{ objectFit: "contain"}} />;
}

export default LazyImage