import React, {Suspense} from 'react';
import {Routes,Route} from "react-router-dom";
import Home from "./Home"
import Blog from './Blog';
import FaqPage from './Faqs/FaqPage';
import Terms from './Terms';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import Refund from './Refund';
import ILoans from './ILoans';
import Partnerps from './Partnerps';
import Aboutus from './Aboutus';
import DGold from './DGold';
import CheckGoldPrice from './CheckGoldPrice';
import GoldPlus from './GoldPlus';
import Career from './Careers/Career';
import Apply from './Apply/Apply';
import JobDetails from './Apply/JobDetails';
import Form from './Apply/Form';
import Digitalgold from './Digitalgold';
import Goldpro from './Goldpro';
import ContactSupport from './ContactSupport';
import IsmsPolicy from './IsmsPolicy';
import Grievance from './Grievance';
import KuberJI from './KuberJI';
import BlogDetails from '../components/BlogDetails';
import KuberPlus from './KuberPlus';
import GoldSIP from './GoldSIP';
import DailySavings from './DailySavings';
import EMIHero from './EMIHero';
import SiteMap from './SiteMap';
import Disclaimer from './Disclaimer';
import SpotPlatform from './SpotPlatform';
import GoldsipMain from '../components/GoldsipMain';
import Goldsipsecond from '../components/Goldsipsecond';

const AboutUsLazyLoading = React.lazy(() => import("../pages/Aboutus"))

const Router = () => {

  return (
    <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/blogs' element={<Blog/>}/>
        <Route path='/faq' element={<FaqPage/>}/>
        <Route path='/terms' element={<Terms/>}/>
        <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
        <Route path='/refunds' element={<Refund/>}/>
        <Route path='/instantloans' element={<ILoans/>}/>
        <Route path='/partnerprogram' element={<Partnerps/>}/>
        <Route path='/aboutus' element={<Suspense fallback={<div>Loading...</div>}><AboutUsLazyLoading/></Suspense>}/>
        <Route path='/dgold' element={<DGold/>}/>
        <Route path='/check-live-gold-price' element={<CheckGoldPrice/>}/>
        <Route path='/gold-plus' element={<GoldPlus/>}/>
        <Route path='/careers' element={<Career/>}/>
        <Route path='/apply' element={<Apply/>}/>
        <Route path="applyjobs/:id" element={<JobDetails/>} />
        <Route path='/form' element={<Form/>}/>
        <Route path='/digitalgold' element={<Digitalgold/>}/>
        <Route path='/goldpro' element={<Goldpro/>}/>   
        <Route path='/contactsupport' element={<ContactSupport/>}/> 
        <Route path='/ismspolicy' element={<IsmsPolicy/>}/>
        <Route path='/grievanceredressal' element={<Grievance/>}/>    
        <Route path='/kuberji' element={<KuberJI/>}/>
        <Route path='/blogdetails' element={<BlogDetails/>}/>
        <Route path='/kuber-plus' element={<KuberPlus/>}/>

        <Route path='/goldsip' element={<GoldSIP/>}/>
        <Route path='/dailysavings' element={<DailySavings/>}/>
        <Route path='/emi-gyan' element={<EMIHero/>}/>
        <Route path='/sitemap' element={<SiteMap/>}/>
        <Route path='/disclaimer' element={<Disclaimer/>}/>
        <Route path='/spot-platform' element={<SpotPlatform/>}/>
        <Route path='/goldsipmain' element={<GoldsipMain/>}/>
        <Route path='/goldsipsecond' element={<Goldsipsecond/>}/>

    </Routes>
  )
}

export default Router