import React from "react";
// import BlogBanner from "../assets/images/blogBanner.png";


const BlogDetails = () => {

  return (
    <div className="bg-custom-color1 text-white m-auto py-20 font-poppinsRegular">
      <div className=" w-[80%] m-auto font-poppinsRegular">
        
        <div className="md:h-[500px] w-[100%]">
          <img
            src='./images/creditcard.jpg'
            alt="blog banner"
            className="h-[100%] w-[100%] rounded-lg"
            // style={{objectFit:"contain"}}
          />
        </div>

        <h1 className="md:text-4xl text-2xl font-poppinsBold py-11 ">
        Dive into Digital Gold: Your Complete Investment Handbook
        </h1>

        <h2 className="text-xl text-[#717171] font-poppinsRegular  py-[2rem]">
        In the world of investments, few assets have stood the test of time like gold. Its enduring value and reputation as a hedge against inflation have made it a preferred choice for investors seeking wealth preservation and security. But in today’s digital age, gold investment has evolved, offering new opportunities and conveniences through digital gold. In this comprehensive guide, we will take you on a journey through the realm of digital gold investment. <br />
          <br />
        </h2>

        <h1 className="md:text-4xl text-2xl font-poppinsBold  ">
          The Global PLS Landscape
        </h1>

        <br />
        <br />

        <ol className="list-decimal font-poppinsRegular text-xl text-[#717171] pl-5">
          <li>
            Fello (India): A recent entrant in the Indian market, Fello stands
            out for its focus on micro-savings and gamification. Users earn
            “Fello Points” for everyday activities like stepping up or
            completing chores, which can be used to participate in prize draws
            for various rewards.
          </li>
          <br />
          <li>
            Yotta (USA): A pioneer in the PLS space, Yotta offers a unique prize
            structure with weekly drawings for various prizes, ranging from cash
            to dream vacations. Users earn “tickets” for each dollar saved,
            increasing their chances of winning with larger deposits.
          </li>
          <br />
          <li>
            Prizepool (USA): This platform boasts a distinctive approach,
            offering monthly prize drawings with a guaranteed winner in each.
            Prizepool partners with various brands and organizations, providing
            participants the chance to win exciting merchandise or experiences.
          </li>
          <br />
          <li>
            Saving (UK): Gaining traction in the UK market, Saving operates
            similarly to Yotta, offering weekly prize draws with varying cash
            rewards. Users earn “entries” based on their deposits, increasing
            their odds of winning.
          </li>
          <br />
          <li>
            Raisin US (USA): While not strictly a PLS platform, Raisin US offers
            a unique feature called “Prize Savings Accounts” from select partner
            banks. These accounts combine FDIC-insured savings with the chance
            to win prizes alongside a base interest rate.
          </li>{" "}
          <br />
          <br />
        </ol>

        <h1 className="md:text-4xl text-2xl font-poppinsBold">
          Beyond the Glitter: A Look Under the Hood
        </h1>

        <br />
        <br />

        <h1 className="text-xl text-[#717171]">
          While the allure of winning prizes is undeniable, it’s crucial to
          understand the underlying aspects of PLS platforms:
        </h1>

        <br />
        <br />

        <ul className="text-xl font-poppinsRegular text-[#717171] list-disc pl-5">
          <li>
            Return on Savings: Unlike traditional savings accounts, PLS
            platforms typically offer lower base interest rates. The potential
            for winning prizes is the primary incentive, and users should not
            solely rely on it for significant returns.
          </li>
          <br />
          <li>
            Fees and Charges: Some PLS platforms may have associated fees, such
            as account maintenance charges or transaction fees. Be mindful of
            these charges and factor them into your decision-making process.
          </li>
          <br />
          <li>
            Regulatory Framework: PLS platforms operate under different
            regulations in various countries. It’s essential to understand the
            specific regulations governing the platform you choose, ensuring its
            legitimacy and security.
          </li>
          <br />
          <li>
            Responsible Gambling: PLS platforms can be engaging, but it’s vital
            to approach them with responsible saving habits and avoid
            overspending due to the allure of prizes.
          </li>
          <br />
          <br />
        </ul>

        <h1 className="md:text-4xl text-2xl font-poppinsBold">Way Forward</h1>

        <br />
        <br />

        <h2 className="text-xl text-[#717171] font-poppinsRegular  py-[2rem]">
          PLS platforms offer a novel approach to saving, merging traditional
          savings with the thrill of winning prizes. However, it’s crucial to
          approach them with informed caution. By understanding the inherent
          limitations, associated risks, and regulatory landscape, you can make
          responsible choices and potentially benefit from the unique features
          offered by these platforms. Remember, responsible saving habits and a
          diversified financial strategy are still essential for achieving
          long-term financial goals.
        </h2>

      </div>
    </div>
  );
};

export default BlogDetails;
