import React, { useState } from "react";
import Navbar from "../components/Navbar";
import signupPartnership from "../assets/images/signups.png";
import refer from "../assets/images/reffer.png";
import commissions from "../assets/images/monthlly commission.png";
import loyalcustomers from "../assets/images/loyal costumer.png";
import { FaApple } from "react-icons/fa";
import Presentingold1 from "../assets/images/Presentingold1.png";
import Brinks from "../assets/images/brinks_logo.jpg";
import vistara from "../assets/images/vistara.png";
const GoldSIP = () => {
  const [activeButton, setActiveButton] = useState('button1');

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };


  return (
    <div className="font-poppinsRegular  bg-custom-color1 text-white w-full f overflow-x-hidden">
      <Navbar backgroundColour={"bg-custom-color1"} />

      <div className="md:w-[85%] w-[90%] pt-5 pb-20 font-poppinsRegular m-auto  container flex items-center justify-center ">
        {/* First Column (Paragraph Section) */}
        <div className="m-auto ">
          <div className=" items-center flex justify-between  flex-col  md:flex-row">
            {/* First Column (Paragraph Section) */}
            <div className="md:w-[52%]">
              <div className=" text-white text-2xl">
                <p className="md:text-4xl text-2xl font-poppinsBold">
                  Buy 24K Gold in 24 Seconds.
                </p>
                <p className="md:text-xl text-lg mt-[1rem]">
                  4,264 transactions in the last 1 hour. <br></br>
                  Start investing in gold starting as loow as ₹10
                </p>
              </div>
              <p className="text-black-700 md:my-6 my-3  lg:text-2xl font-semibold  font-poppinsRegular  text-white leading-12 md:text-2xl">
                Secured by
              </p>
              <div className="md:w-[60%] w-[100%] h-20  flex gap-5 items-center cursor-pointer">
                <div className="h-16 md:w-36 w-[48%] border p-[0.3rem] text-white rounded-lg flex items-center justify-center">
                  <img
                    src="./images/bis.webp"
                    alt="google"
                    className="w-10 h-10"
                    style={{ objectFit: "contain" }}
                  />
                </div>
                <div className="h-16 md:w-36 w-[48%] border p-[0.5rem] text-white rounded-lg flex   justify-center">
                  <div className="text-white">
                    <img
                      src="./images/NABL.webp"
                      alt="google"
                      className="h-10 w-10"
                      style={{ objectFit: "contain" }}
                    />
                  </div>
                </div>
              </div>
              <p className="md:text-xl text-lg font-poppinsRegular mt-[1rem]">
                Gold corresponding to every purchase you make is stored in
                world-class vaults.
              </p>
            </div>

            {/* Second Column (Image Section) */}
            <div className="md:w-[48%] w-full flex justify-center h-100% ">
              <img
                src={Presentingold1}
                alt="twsimage"
                style={{ objectFit: "contain" }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* 2nd section */}
      <div className="h-max-content md:py-10 py-5 bg-custom-color2  flex flex-col  items-center justify-center w-[100%]">
        <div className="w-[85%] ">
          <div className=" m-auto text-white my-2 flex flex-col gap-3 items-center justify-center">
            <div className=" w-[100%]">
              <h2 className="text-3xl md:text-4xl text-center font-bold text-gray-400 font-poppinsRegular">
                Why go digital ?
              </h2>
            </div>
          </div>

          <div className="grid lg:grid-cols-3 gap-16 md:mt-[3rem] mt-[2rem] items-center">
            <div className="h-max-content p-[0.3rem] text-white rounded-lg flex items-between gap-[2rem]">
              <img
                src="./images/bis.webp"
                alt="google"
                className="w-10 h-16"
                style={{ objectFit: "contain" }}
              />
              <div className="text-white leading-3">
                <p className="text-lg  text-gray-400  font-poppinsBold">
                  Guaranteed 24K Gold
                </p>
                <p className="md:text-md text-sm mt-[0.5rem] font-poppinsRegular">
                  Unlike local vendors, with KuberPe, you directly buy from the
                  manufacture
                </p>
              </div>
            </div>
            <div className="h-max-content p-[0.3rem] text-white rounded-lg flex items-center gap-[2rem]">
              <img
                src="./images/bis.webp"
                alt="google"
                className="w-10 h-16"
                style={{ objectFit: "contain" }}
              />
              <div className="text-white leading-3">
                <p className="text-lg  text-gray-400  font-poppinsBold ">    
                  Sell anytime from home
                </p>
                <p className="md:text-md text-sm font-poppinsRegular mt-[0.5rem]">
                  Sell anytime, without going anywhere and receive money direct in your account.
                </p>
              </div>
            </div>
            <div className="h-max-content p-[0.3rem] text-white rounded-lg flex items-center justify-between gap-[2rem]">
              <img
                src="./images/bis.webp"
                alt="google"
                className="w-10 h-16"
                style={{ objectFit: "contain" }}
              />
              <div className="text-white leading-3">
                <p className="text-lg  text-gray-400  font-poppinsBold">
                  Earn income on gold
                </p>
                <p className="md:text-md text-sm font-poppinsRegular mt-[0.5rem]">
                  You can lend digital gold to SafeGold-verified borrowers and earn monthly rental income in the form of gold.
                </p>
              </div>
            </div>
            <div className="h-max-content p-[0.3rem] text-white rounded-lg flex items-center justify-between gap-[2rem]">
              <img
                src="./images/bis.webp"
                alt="google"
                className="w-10 h-16"
                style={{ objectFit: "contain" }}
              />
              <div className="text-white leading-3">
                <p className="text-lg  text-gray-400  font-poppinsBold">
                  Safety guaranteed
                </p>
                <p className="md:text-md text-sm font-poppinsRegular mt-[0.5rem]">
                   Unlike physical gold, you don’t have to worry about theft or expensive locker fees. You gold is stored in bank-grade lockers free of cost.
                </p>
              </div>
            </div>
            <div className="h-max-content p-[0.3rem] text-white rounded-lg flex items-center justify-between gap-[2rem]">
              <img
                src="./images/bis.webp"
                alt="google"
                className="w-10 h-16"
                style={{ objectFit: "contain" }}
              />
              <div className="text-white leading-3">
                <p className="text-lg  text-gray-400  font-poppinsBold">
                   Convert to physical gold
                </p>
                <p className="md:text-md text-sm mt-[0.5rem]">
                  You can convert your digital gold to physical gold anytime in the form of coins or jewellery through our partners.
                </p>
              </div>
            </div>{" "}
            <div className="h-max-content  p-[0.3rem] text-white rounded-lg flex items-center justify-between gap-[2rem]">
              <img
                src="./images/bis.webp"
                alt="google"
                className="w-10 h-16"
                style={{ objectFit: "contain" }}
              />
              <div className="text-white leading-3 ">
                <p className="text-lg  text-gray-400  font-poppinsBold">      
                   Buy as low as ₹10
                </p>
                <p className="md:text-md text-sm mt-[0.5rem]">
                   Digital does not require to invest a large sum of money. You can invest based on your budget.
                </p>
              </div>
            </div>
            {/* Repeat the above code for the remaining items */}
          </div>
        </div>
      </div>

      <div className="md:w-[85%] w:[100%] pt-5 pb-20 font-poppinsRegular m-auto  container flex items-center justify-center ">
        {/* First Column (Paragraph Section) */}
        <div className="m-auto ">
          <div className=" items-center flex justify-between  flex-col  md:flex-row">
            {/* First Column (Paragraph Section) */}
            <div className="md:w-[52%]">
              <div className=" text-white lg:h-16 lg:text-5xl font-poppinsBold text-xl">
                <p>Better than an FD</p>
              </div>
              <p className="my-6   xs:text-center   text-white leading-12 text-sm lg:text-xl">
                A 1000 investment 5 years ago would be
              </p>
              <div className="lg:w-[80%]  w-[100%] flex gap-10  ">
                <div className="h-16 text-white    flex justify-between rounded-lg  w-28 lg:w-[20%]">
                  <div className=" h-7 w-7 bg-caribbeangreen-200 rounded-full"></div>
                  <div className="text-white   ">
                    <p className="text-lg text-gray-400  h-7 ">Gold</p>
                    <p className="text-xl">₹2223 </p>
                  </div>
                </div>

                <div className="h-16  text-white flex justify-between  w-28 rounded-lg lg:w-[20%]">
                  <div className="text-white">
                    <div className=" h-7 w-7  bg-brown-50 rounded-full"></div>
                  </div>
                  <div className="text-white  ">
                    <p className="text-lg text-gray-400">FD</p>
                    <p className="text-xl">₹1383</p>
                  </div>
                </div>
              </div>

              <div className="md:w-[80%]  h-max-content border border-richblack-900 bg-custom-color2 my-10 rounded-xl  lg:w-[60%] md:h-[85%] w-[100%]  xs:p-[1rem] md:p-[0px] ">
                <div className=" w-[75%] m-auto my-8  ">
                  <p className="text-lg">Grow your wealth smarter</p>
                  <p className="font-poppinsRegular text-sm mt-[0.5rem]">
                    Start an SIP to invest in gold every month
                  </p>
                </div>
                <div className="w-[75%] m-auto md:pb-8 pb-5">
                  <button className="font-poppinsRegular  w-[90%]   w-40 lg:w-[80%] font-bold  bg-custom-color4 h-12 rounded-lg text-white md:text-xl text-center   xs:text-sm  ">
                    Start investing
                  </button>
                </div>
              </div>
            </div>

            {/* Second Column (Image Section) */}
            <div className="md:w-[48%] w-full flex justify-center h-100% ">
              <img
                src={Presentingold1}
                alt="twsimage"
                style={{ objectFit: "contain" }}
              />
            </div>
          </div>
        </div>
      </div>
          

      <div className=" h-max-content bg-custom-color2 ">
          <div className=" md:w-[90%]  w-[100%]  m-auto text-white  h-28 flex flex-col gap-3 items-center justify-center">
            <h1 className=" font-poppinsBold  text-center md:text-4xl text-3xl"> Digital to physical in minutes</h1>          
          </div>
          <div className="md:w-[50%] h-[50px]  lg:w-[50%]  m-auto w-[100%]   md:text-2xl "> 
            <div className="w-[100%]  flex m-auto justify-center  h-[50%] items-center gap-[1rem] xs:flex-row  ">      
              <button  onClick={() => handleButtonClick('button1') } className="font-poppinsRegular lg:w-40    h-12 rounded-lg text-white text-xl text-center   xs:text-sm  ">
                Coins
              </button>

              <button onClick={() => handleButtonClick('button2')} className="font-poppinsRegular  text-white h-12 rounded-lg text-center md:text  text-xl xs:text-sm  ">
                  Digital Gold
              </button>
            </div>
          </div>

          <div className=" w-[100%]   flex items-center justify-center text-white">

          {activeButton === 'button1' && (
        <div>
        <div className="container flex items-center m-auto justify-center w-[85%]">
          <div className="md:w-[100%] w-[100%]  h-[100%]  flex justify-between items-center flex-col  md:flex-row">
           
            <div className="md:w-[48%]  w-[100%] flex justify-end    md:h-[100%]  h-[50%]">
              <img src="./images/partnerskr.webp" alt="twsimage" style={{objectFit:"contain"}}/>
            </div>
            
            <div className="md:w-[50%]  w-[100%] h-max-content xs:p-[1rem] md:p-[0px] md:text-2xl pb-[4rem]">
                <div className="  lg:w-[60%]  lg:mt-28">
                  <p className="text-lg h-16 font-poppinsRegular font-semibold">24K Gold Coins & Bars delivered to your doorstep</p>
                  <p className="font-poppinsRegular text-sm">
                    Convert your digital gold to physical gold by paying a nominal minting charge. Your delivery comes with free insurance, to ensure your coins and bars reach you safely.
                  </p>
                </div>
            </div> 
          </div>
        </div> 
      </div>
      )}
           
      </div>
    {/* second */}
              
     {activeButton === 'button2' && (
      <div className=" w-[85%] m-auto  flex items-center justify-center text-white pb-[4rem]">
        <div className="w-[100%] m-auto">
          <div className="w-[100%] text-center">
            <h2 className=" h-12 text-2xl font-poppinsRegular font-semibold">Redeem Offline</h2>
          </div>
          <div className="w-[100%] text-center font-poppinsRegular">
              <p className="text-center">Redeem by sharing your SafeGold number at the CaratLane store. Verify using an OTP and pay using your SafeGold balance.</p>
          </div>
        </div>
      </div>
      )}
    </div>
    </div>
  );
};

export default GoldSIP;
